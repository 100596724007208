* {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

.access-text {
    font-size: 12px;
    line-height: 1.6em;
    font-weight: 500;
    margin-top: 0;
}

md-menu-content {
    max-height: 400px;
}

.md-avatar {
    &.md-warn:before {
        color: red;
    }
}

// Multi-line tooltips
.tooltip--multiline {
    height: auto;
}

md-card {
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 1px 1px 0 rgba(0, 0, 0, 0.5), 0 2px 1px -1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 1px 1px 0 rgba(0, 0, 0, 0.5), 0 2px 1px -1px rgba(0, 0, 0, 0.5)
}

[ng-quill-editor] {
    display: block;
}

// Custom width class
//.w-800 {
//    width: 800px !important;
//    min-width: 800px !important;
//    max-width: 800px !important;
//}

// Hide the Zendesk Web Widget
body > div > iframe#launcher {
    display: none !important;
}

.ui-icon,
.ui-widget-content .ui-icon,
.ui-widget-header .ui-icon {
    background-image: url('../../images/jquery_ui/ui-icons_444444_256x240.png');
}

.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-button:hover .ui-icon,
.ui-button:focus .ui-icon {
    background-image: url('../../images/jquery_ui/ui-icons_555555_256x240.png');
}
.ui-state-active .ui-icon,
.ui-button:active .ui-icon {
    background-image: url('../../images/jquery_ui/ui-icons_ffffff_256x240.png');
}
.ui-state-highlight .ui-icon,
.ui-button .ui-state-highlight.ui-icon {
    background-image: url('../../images/jquery_ui/ui-icons_777620_256x240.png');
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
    background-image: url('../../images/jquery_ui/ui-icons_cc0000_256x240.png');
}
.ui-button .ui-icon {
    background-image: url('../../images/jquery_ui/ui-icons_777777_256x240.png');
}

// Cursor classes
.mouse-pointer {
    cursor: pointer;
}

.mouse-grab {
    cursor: grab;
}

.mouse-grabbing {
    cursor: grabbing;
}

.mouse-crosshair {
    cursor: crosshair;
}

.mouse-alias {
    cursor: alias;
}

.mouse-all-scroll {
    cursor: all-scroll;
}

.mouse-cell {
    cursor: cell;
}

.mouse-copy {
    cursor: copy;
}

.mouse-col-resize {
    cursor: col-resize;
}

.mouse-context-menu {
    cursor: context-menu;
}

.mouse-e-resize {
    cursor: e-resize;
}

.mouse-ew-resize {
    cursor: ew-resize;
}

.mouse-w-resize {
    cursor: w-resize;
}

.mouse-progress {
    cursor: progress;
}

.mouse-wait {
    cursor: wait;
}

body {
    overflow: hidden !important;

    #main {
        overflow: hidden !important;
    }
}

/* Transition Loading Message */
#ui-view-loading-message {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background: rgba(255, 255, 255, 0.93);
    z-index: 2;
    display: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 8vh;
    color: $color-blue;

    &.visible {
        display: flex;
    }
}

/* Error Page Styles */
.page--error {
    .static--wrapper {
        padding: 0.8rem 1rem;
        border-radius: 3px;

        .static-logo {
            max-width: 400px;
        }
    }
}