[ag-grid] {
    height: calc(100vh - 200px);

    .md-panel & {
        height: calc(100vh - 365px);
        max-height: 650px;
    }

    .md-button,
    .md-dense & .md-button,
    .md-dense & > .md-button:not(.md-dense-disabled),
    .md-dense & :not(.md-dense-disabled) .md-button:not(.md-dense-disabled) {
        font-size: 12px;
        margin: 0 5px 0 0;
        padding: 1px 7px 2px;
        line-height: normal;
        min-width: 0;
        min-height: 0;
    }

    .ag-cell.cell-wrap-text {
        white-space: normal;
        line-height: 1.6rem;
    }
}

#content, md-dialog {
    .ag-theme-balham {
        .ag-paging-panel {
            color: #000;
        }

        .ag-row-odd:not(.ag-row-selected) {
            background-color: #eaeaea;
        }

        button[ref="btFirst"],
        button[ref="btPrevious"],
        button[ref="btNext"],
        button[ref="btLast"] {
            opacity: 1;

            &[disabled] {
                opacity: 0.38;
            }
        }

        .ag-floating-filter-body {
            .ag-input-text-wrapper {
                input[type="date"] {
                    height: 20px;
                    margin: 0;
                }
            }
        }
    }
}

.mms-ag-search-container {
    padding: 4px;
    min-width: 200px;
}

.mms-ag-search-apply {
    padding: 0 8px;
    margin: 5px 0;
}

.mms-ag-search-loading {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
}

.mms-ag-search-reset {
    -webkit-appearance: button;
    border-radius: 4px;
    border: 1px solid #d1d1d1;
    margin: 0 0 0 8px;
    padding: 1px 7px 2px;
}

.mms-ag-search-results {
    margin: 5px auto 0 16px;
    padding: 0 8px 0;

    li {
        margin-bottom: 5px;
        white-space: nowrap;

        a:focus {
            outline: 1px dotted map-deep-get($materialColorsMap, 'light-blue', 'hues', '600');
        }
    }
}

.full-width-panel {
    background: #fff;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 5px;
    overflow: scroll;
}

.full-width-detail {
    padding-top: 4px;
}

.full-width-details {
    padding: 5px;
    margin: 5px;
}

.full-width-grid {
    padding-top: 16px;
    display: block;
    height: 100%;
}