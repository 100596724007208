.md-button {
    text-transform: none;

    &:not([disabled]) {
        &.md-raised {
            border: 1px solid rgba(0, 0, 0, 0.12);
        }

        &.md-raised:active,
        &.md-fab:active {
            box-shadow: 0 0 0 0;
        }
    }
}

.ag-cell {
    // Remove drop shadow on md-raised
    .md-button.md-raised:not([disabled]) {
        box-shadow: 0 0 0 transparent;
        transition: none;
        border: 0;

        &:active {
            box-shadow: 0 0 0 transparent;
        }
    }
}