@import 'partials/reset';
@import 'partials/variables';
@import 'partials/mixins';
@import 'partials/angular-material-extend';
@import 'partials/layouts-template';
@import 'partials/layouts-page';
@import 'partials/animations';
@import 'partials/colors';
@import 'partials/icons';
//@import 'partials/material';
@import 'partials/ms-card';
@import 'partials/ms-form-wizard';
//@import 'partials/ms-info-bar';
//@import 'partials/ms-masonry';
@import 'partials/ms-nav';
@import 'partials/ms-navigation';
@import 'partials/ms-responsive-table';
@import 'partials/ms-scroll';
@import 'partials/ms-search-bar';
//@import 'partials/ms-shortcuts';
//@import 'partials/ms-splash-screen';
@import 'partials/ms-stepper';
@import 'partials/ms-widget';
@import 'partials/navigation';
@import 'partials/toolbar-main';
//@import 'partials/toolbar';
@import 'partials/typography';
//@import 'partials/quick-panel';
//@import 'partials/quick-panel';
//@import 'partials/plugins/c3';
//@import 'partials/plugins/chartist';
//@import 'partials/plugins/chartjs';
//@import 'partials/plugins/highlight';
//@import 'partials/plugins/nvd3';
@import 'partials/plugins/text-angular';
@import 'partials/helpers';
@import 'partials/print';

// Content hack because they wont fix
// https://github.com/angular/material/pull/8067
[md-theme="default"] md-content.md-hue-1,
md-content.md-default-theme.md-hue-1 {
    color: $backgroundHue1Contrast1;
    background-color: $backgroundHue1Color;
}

[md-theme="default"] md-content.md-hue-2,
md-content.md-default-theme.md-hue-2 {
    color: $backgroundHue2Contrast1;
    background-color: $backgroundHue2Color;
}

[md-theme="default"] md-content.md-hue-3,
md-content.md-default-theme.md-hue-3 {
    color: $backgroundHue3Contrast1;
    background-color: $backgroundHue3Color;
}

// Text Colors
[md-theme="default"] a {
    color: $accentDefaultColor;
}

[md-theme="default"] .secondary-text,
[md-theme="default"] .icon {
    color: $backgroundDefaultContrast2;
}

[md-theme="default"] .hint-text,
[md-theme="default"] .disabled-text {
    color: $backgroundDefaultContrast3;
}

[md-theme="default"] .fade-text,
[md-theme="default"] .divider {
    color: $backgroundDefaultContrast4;
}

// Primary
[md-theme="default"] .md-primary-bg {
    background-color: $primaryDefaultColor;
    color: $primaryDefaultContrast1;
}

[md-theme="default"] .md-primary-bg .secondary-text,
[md-theme="default"] .md-primary-bg .icon {
    color: $primaryDefaultContrast2;
}

[md-theme="default"] .md-primary-bg .hint-text,
[md-theme="default"] .md-primary-bg .disabled-text {
    color: $primaryDefaultContrast3;
}

[md-theme="default"] .md-primary-bg .fade-text,
[md-theme="default"] .md-primary-bg .divider {
    color: $primaryDefaultContrast4;
}

// Primary, Hue-1
[md-theme="default"] .md-primary-bg.md-hue-1 {
    background-color: $primaryHue1Color;
    color: $primaryHue1Contrast1;
}

[md-theme="default"] .md-primary-bg.md-hue-1 .secondary-text,
[md-theme="default"] .md-primary-bg.md-hue-1 .icon {
    color: $primaryHue1Contrast2;
}

[md-theme="default"] .md-primary-bg.md-hue-1 .hint-text,
[md-theme="default"] .md-primary-bg.md-hue-1 .disabled-text {
    color: $primaryHue1Contrast3;
}

[md-theme="default"] .md-primary-bg.md-hue-1 .fade-text,
[md-theme="default"] .md-primary-bg.md-hue-1 .divider {
    color: $primaryHue1Contrast4;
}

// Primary, Hue-2
[md-theme="default"] .md-primary-bg.md-hue-2 {
    background-color: $primaryHue2Color;
    color: $primaryHue2Contrast1;
}

[md-theme="default"] .md-primary-bg.md-hue-2 .secondary-text,
[md-theme="default"] .md-primary-bg.md-hue-2 .icon {
    color: $primaryHue2Contrast2;
}

[md-theme="default"] .md-primary-bg.md-hue-2 .hint-text,
[md-theme="default"] .md-primary-bg.md-hue-2 .disabled-text {
    color: $primaryHue2Contrast3;
}

[md-theme="default"] .md-primary-bg.md-hue-2 .fade-text,
[md-theme="default"] .md-primary-bg.md-hue-2 .divider {
    color: $primaryHue2Contrast4;
}

// Primary, Hue-3
[md-theme="default"] .md-primary-bg.md-hue-3 {
    background-color: $primaryHue3Color;
    color: $primaryHue3Contrast1;
}

[md-theme="default"] .md-primary-bg.md-hue-3 .secondary-text,
[md-theme="default"] .md-primary-bg.md-hue-3 .icon {
    color: $primaryHue3Contrast1;
}

[md-theme="default"] .md-primary-bg.md-hue-3 .hint-text,
[md-theme="default"] .md-primary-bg.md-hue-3 .disabled-text {
    color: $primaryHue3Contrast3;
}

[md-theme="default"] .md-primary-bg.md-hue-3 .fade-text,
[md-theme="default"] .md-primary-bg.md-hue-3 .divider {
    color: $primaryHue3Contrast4;
}

// Primary foreground
[md-theme="default"] .md-primary-fg {
    color: $primaryDefaultColor !important;
}

// Primary foreground, Hue-1
[md-theme="default"] .md-primary-fg.md-hue-1 {
    color: $primaryHue1Color !important;
}

// Primary foreground, Hue-2
[md-theme="default"] .md-primary-fg.md-hue-2 {
    color: $primaryHue2Color !important;
}

// Primary foreground, Hue-3
[md-theme="default"] .md-primary-fg.md-hue-3 {
    color: $primaryHue3Color !important;
}

// Accent
[md-theme="default"] .md-accent-bg {
    background-color: $accentDefaultColor;
    color: $accentDefaultContrast1;
}

[md-theme="default"] .md-accent-bg .secondary-text,
[md-theme="default"] .md-accent-bg .icon {
    color: $accentDefaultContrast2;
}

[md-theme="default"] .md-accent-bg .hint-text,
[md-theme="default"] .md-accent-bg .disabled-text {
    color: $accentDefaultContrast3;
}

[md-theme="default"] .md-accent-bg .fade-text,
[md-theme="default"] .md-accent-bg .divider {
    color: $accentDefaultContrast4;
}

// Accent, Hue-1
[md-theme="default"] .md-accent-bg.md-hue-1 {
    background-color: $accentHue1Color;
    color: $accentHue1Contrast1;
}

[md-theme="default"] .md-accent-bg.md-hue-1 .secondary-text,
[md-theme="default"] .md-accent-bg.md-hue-1 .icon {
    color: $accentHue1Contrast2;
}

[md-theme="default"] .md-accent-bg.md-hue-1 .hint-text,
[md-theme="default"] .md-accent-bg.md-hue-1 .disabled-text {
    color: $accentHue1Contrast3;
}

[md-theme="default"] .md-accent-bg.md-hue-1 .fade-text,
[md-theme="default"] .md-accent-bg.md-hue-1 .divider {
    color: $accentHue1Contrast4;
}

// Accent, Hue-2
[md-theme="default"] .md-accent-bg.md-hue-2 {
    background-color: $accentHue2Color;
    color: $accentHue2Contrast1;
}

[md-theme="default"] .md-accent-bg.md-hue-2 .secondary-text,
[md-theme="default"] .md-accent-bg.md-hue-2 .icon {
    color: $accentHue2Contrast2;
}

[md-theme="default"] .md-accent-bg.md-hue-2 .hint-text,
[md-theme="default"] .md-accent-bg.md-hue-2 .disabled-text {
    color: $accentHue2Contrast3;
}

[md-theme="default"] .md-accent-bg.md-hue-2 .fade-text,
[md-theme="default"] .md-accent-bg.md-hue-2 .divider {
    color: $accentHue2Contrast4;
}

// Accent, Hue-3
[md-theme="default"] .md-accent-bg.md-hue-3 {
    background-color: $accentHue3Color;
    color: $accentHue3Contrast1;
}

[md-theme="default"] .md-accent-bg.md-hue-3 .secondary-text,
[md-theme="default"] .md-accent-bg.md-hue-3 .icon {
    color: $accentHue3Contrast1;
}

[md-theme="default"] .md-accent-bg.md-hue-3 .hint-text,
[md-theme="default"] .md-accent-bg.md-hue-3 .disabled-text {
    color: $accentHue3Contrast3;
}

[md-theme="default"] .md-accent-bg.md-hue-3 .fade-text,
[md-theme="default"] .md-accent-bg.md-hue-3 .divider {
    color: $accentHue3Contrast4;
}

// Accent foreground
[md-theme="default"] .md-accent-fg {
    color: $accentDefaultColor !important;
}

// Accent foreground, Hue-1
[md-theme="default"] .md-accent-fg.md-hue-1 {
    color: $accentHue1Color !important;
}

// Accent foreground, Hue-2
[md-theme="default"] .md-accent-fg.md-hue-2 {
    color: $accentHue2Color !important;
}

// Accent foreground, Hue-3
[md-theme="default"] .md-accent-fg.md-hue-3 {
    color: $accentHue3Color !important;
}

// Warn
[md-theme="default"] .md-warn-bg {
    background-color: $warnDefaultColor;
    color: $warnDefaultContrast1;
}

[md-theme="default"] .md-warn-bg .secondary-text,
[md-theme="default"] .md-warn-bg .icon {
    color: $warnDefaultContrast2;
}

[md-theme="default"] .md-warn-bg .hint-text,
[md-theme="default"] .md-warn-bg .disabled-text {
    color: $warnDefaultContrast3;
}

[md-theme="default"] .md-warn-bg .fade-text,
[md-theme="default"] .md-warn-bg .divider {
    color: $warnDefaultContrast4;
}

// Warn, Hue-1
[md-theme="default"] .md-warn-bg.md-hue-1 {
    background-color: $warnHue1Color;
    color: $warnHue1Contrast1;
}

[md-theme="default"] .md-warn-bg.md-hue-1 .secondary-text,
[md-theme="default"] .md-warn-bg.md-hue-1 .icon {
    color: $warnHue1Contrast2;
}

[md-theme="default"] .md-warn-bg.md-hue-1 .hint-text,
[md-theme="default"] .md-warn-bg.md-hue-1 .disabled-text {
    color: $warnHue1Contrast3;
}

[md-theme="default"] .md-warn-bg.md-hue-1 .fade-text,
[md-theme="default"] .md-warn-bg.md-hue-1 .divider {
    color: $warnHue1Contrast4;
}

// Warn, Hue-2
[md-theme="default"] .md-warn-bg.md-hue-2 {
    background-color: $warnHue2Color;
    color: $warnHue2Contrast1;
}

[md-theme="default"] .md-warn-bg.md-hue-2 .secondary-text,
[md-theme="default"] .md-warn-bg.md-hue-2 .icon {
    color: $warnHue2Contrast2;
}

[md-theme="default"] .md-warn-bg.md-hue-2 .hint-text,
[md-theme="default"] .md-warn-bg.md-hue-2 .disabled-text {
    color: $warnHue2Contrast3;
}

[md-theme="default"] .md-warn-bg.md-hue-2 .fade-text,
[md-theme="default"] .md-warn-bg.md-hue-2 .divider {
    color: $warnHue2Contrast4;
}

// Warn, Hue-3
[md-theme="default"] .md-warn-bg.md-hue-3 {
    background-color: $warnHue3Color;
    color: $warnHue3Contrast1;
}

[md-theme="default"] .md-warn-bg.md-hue-3 .secondary-text,
[md-theme="default"] .md-warn-bg.md-hue-3 .icon {
    color: $warnHue3Contrast1;
}

[md-theme="default"] .md-warn-bg.md-hue-3 .hint-text,
[md-theme="default"] .md-warn-bg.md-hue-3 .disabled-text {
    color: $warnHue3Contrast3;
}

[md-theme="default"] .md-warn-bg.md-hue-3 .fade-text,
[md-theme="default"] .md-warn-bg.md-hue-3 .divider {
    color: $warnHue3Contrast4;
}

// Warn foreground
[md-theme="default"] .md-warn-fg {
    color: $warnDefaultColor !important;
}

// Warn foreground, Hue-1
[md-theme="default"] .md-warn-fg.md-hue-1 {
    color: $warnHue1Color !important;
}

// Warn foreground, Hue-2
[md-theme="default"] .md-warn-fg.md-hue-2 {
    color: $warnHue2Color !important;
}

// Warn foreground, Hue-3
[md-theme="default"] .md-warn-fg.md-hue-3 {
    color: $warnHue3Color !important;
}

// Background
[md-theme="default"] .md-background-bg {
    background-color: $backgroundDefaultColor;
    color: $backgroundDefaultContrast1;
}

[md-theme="default"] .md-background-bg .secondary-text,
[md-theme="default"] .md-background-bg .icon {
    color: $backgroundDefaultContrast2;
}

[md-theme="default"] .md-background-bg .hint-text,
[md-theme="default"] .md-background-bg .disabled-text {
    color: $backgroundDefaultContrast3;
}

[md-theme="default"] .md-background-bg .fade-text,
[md-theme="default"] .md-background-bg .divider {
    color: $backgroundDefaultContrast4;
}

// Background, Hue-1
[md-theme="default"] .md-background-bg.md-hue-1 {
    background-color: $backgroundHue1Color;
    color: $backgroundHue1Contrast1;
}

[md-theme="default"] .md-background-bg.md-hue-1 .secondary-text,
[md-theme="default"] .md-background-bg.md-hue-1 .icon {
    color: $backgroundHue1Contrast2;
}

[md-theme="default"] .md-background-bg.md-hue-1 .hint-text,
[md-theme="default"] .md-background-bg.md-hue-1 .disabled-text {
    color: $backgroundHue1Contrast3;
}

[md-theme="default"] .md-background-bg.md-hue-1 .fade-text,
[md-theme="default"] .md-background-bg.md-hue-1 .divider {
    color: $backgroundHue1Contrast4;
}

// Background, Hue-2
[md-theme="default"] .md-background-bg.md-hue-2 {
    background-color: $backgroundHue2Color;
    color: $backgroundHue2Contrast1;
}

[md-theme="default"] .md-background-bg.md-hue-2 .secondary-text,
[md-theme="default"] .md-background-bg.md-hue-2 .icon {
    color: $backgroundHue2Contrast2;
}

[md-theme="default"] .md-background-bg.md-hue-2 .hint-text,
[md-theme="default"] .md-background-bg.md-hue-2 .disabled-text {
    color: $backgroundHue2Contrast3;
}

[md-theme="default"] .md-background-bg.md-hue-2 .fade-text,
[md-theme="default"] .md-background-bg.md-hue-2 .divider {
    color: $backgroundHue2Contrast4;
}

// Background, Hue-3
[md-theme="default"] .md-background-bg.md-hue-3 {
    background-color: $backgroundHue3Color;
    color: $backgroundHue3Contrast1;
}

[md-theme="default"] .md-background-bg.md-hue-3 .secondary-text,
[md-theme="default"] .md-background-bg.md-hue-3 .icon {
    color: $backgroundHue3Contrast1;
}

[md-theme="default"] .md-background-bg.md-hue-3 .hint-text,
[md-theme="default"] .md-background-bg.md-hue-3 .disabled-text {
    color: $backgroundHue3Contrast3;
}

[md-theme="default"] .md-background-bg.md-hue-3 .fade-text,
[md-theme="default"] .md-background-bg.md-hue-3 .divider {
    color: $backgroundHue3Contrast4;
}

// Background foreground
[md-theme="default"] .md-background-fg {
    color: $backgroundDefaultColor !important;
}

// Background foreground, Hue-1
[md-theme="default"] .md-background-fg.md-hue-1 {
    color: $backgroundHue1Color !important;
}

// Background foreground, Hue-2
[md-theme="default"] .md-background-fg.md-hue-2 {
    color: $backgroundHue2Color !important;
}

// Background foreground, Hue-3
[md-theme="default"] .md-background-fg.md-hue-3 {
    color: $backgroundHue3Color !important;
}