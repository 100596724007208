md-select {
    margin: 0;
}

/*md-input-container:not(.md-input-invalid) {
    .md-errors-spacer {
        display: none;
    }
}*/

.hint {
    position: absolute;
    left: 2px;
    right: auto;
    bottom: 7px;
    font-size: 12px;
    line-height: 14px;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    color: grey;

    &.hint-relative {
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
    }
}

._md-datepicker-floating-label {
    > md-datepicker {
        .md-datepicker-button {
            margin-top: -12px !important;
        }

        .md-datepicker-input-container {
            margin-left: 0;
        }
    }

    &._md-datepicker-has-calendar-icon {
        > label:not(.md-no-float):not(.md-container-ignore) {
            right: auto;
            left: 40px;
        }
    }
}

._md-datepicker-has-calendar-icon {
    md-datepicker {
        .md-datepicker-input-container {
            margin-left: 0;
        }
    }
}

.md-autocomplete-suggestions-container {
    border: 1px solid #aaa;
    height: 100% !important;

    .md-autocomplete-suggestion {
        display: flex;
        flex-flow: column;
        justify-content: center;
        line-height: 1.6rem;
        border-bottom: 1px solid #ededed;

        .highlight {
            background: #ffeb3b;
        }
    }
}

[disabled] md-input-container.md-default-theme .md-input,
[disabled] md-input-container .md-input,
md-input-container.md-default-theme .md-input[disabled],
md-input-container .md-input[disabled] {
    color: rgba(0, 0, 0, 0.38);
}

md-input-container {
    .input-hint {
        bottom: auto;
        top: 100%;
        color: rgba(0, 0, 0, 0.8);
    }

    &.md-default-theme:not(.md-input-invalid),
    &:not(.md-input-invalid) {
        &.md-input-has-value {
            label {
                color: #000;
            }
        }
    }

    &._md-datepicker-floating-label.md-input-focused,
    &._md-datepicker-floating-label.md-input-has-placeholder,
    &._md-datepicker-floating-label.md-input-has-value {
        label:not(.md-no-float) {
            bottom: 80%;

            .md-cell & {
                bottom: 100%;
            }

            .message-prompt-form & {
                bottom: 90%;
            }
        }
    }

    &.md-default-theme.md-input-focused,
    &.md-default-theme.md-input-has-value,
    &.md-input-focused,
    &.md-input-has-value {
        label {
            font-weight: bold;
        }
    }

    &.md-default-theme .md-placeholder,
    .md-placeholder,
    &.md-default-theme label,
    label {
        color: #000;
    }

    md-select[required]:not(.md-no-asterisk):not(.ng-valid),
    md-select.ng-required:not(.md-no-asterisk):not(.ng-valid),
    md-select.ng-invalid-required:not(.md-no-asterisk):not(.ng-valid) {
        .md-select-value span:first-child {
            color: rgb(213, 0, 0);

            &:after {
                color: rgb(213, 0, 0) !important;
            }
        }
    }

    label.md-required {
        color: rgb(213, 0, 0);

        &:after {
            color: rgb(213, 0, 0) !important;
        }
    }

    &.md-input-focused label:not(.md-no-float),
    &.md-input-has-placeholder label:not(.md-no-float),
    &.md-input-has-value label:not(.md-no-float) {
        font-size: 75%;
        transform: translate3d(0px, 0px, 0);
    }
}

.md-select-menu-container {
    z-index: 100;
}

.ag-theme-balham {
    .ag-floating-filter-input {
        transition: border 150ms linear;

        &:focus {
            border: 1px solid #2196F3;
        }
    }

    .ag-filter-filter {
        transition: border 150ms linear;
        border: 1px solid rgb(238, 238, 238);
        margin: 0 4px 4px;
        padding: 1px 1px 1px 4px;

        &:focus {
            border: 1px solid #2196F3;
        }
    }

    .ag-filter .ag-filter-apply-panel button + button {
        -webkit-appearance: button;
        border-radius: 4px;
        border: 1px solid rgb(209, 209, 209);
        margin: 0 0 0 8px;
        padding: 1px 7px 2px;
    }
}