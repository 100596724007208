.ta-root {
    padding-bottom: 0;
}

.ta-toolbar {
    background-color: #F0F0F0;
    margin-left: 0;
    border: 1px solid #EEE;
    padding: 1em;
    float: left;
    width: 100%;
}

.ta-editor, .white-box {
    padding: 10px;
    background-color: #FFF;
    border: 1px solid #EEE;
}

.ta-root.focussed > .ta-scroll-window.form-control {
    border-color: #2d323e;
    outline: 0;
    box-shadow: inset 0 0 3px #2d323e;
    border-bottom: 0;
}

.ta-scroll-window > .ta-bind {
    height: auto;
    min-height: 300px;
    padding: 0 12px;
}

textarea.ta-bind {
    width: 100%;
}

.btn-group {
    margin: 0.2em 0.4em 0.2em 0;
    float: left;
    width: auto;

    button,
    .btn {
        background: #fff;
        width: 35px;
        height: 35px;
        border: 1px solid #ddd;
        border-right: 0;
        float: left;
        line-height: 35px;
        text-align: center;
        transition: background 300ms ease-in-out;

        &:hover:not(#toolbarWC):not(#toolbarCC),
        &.active:not(#toolbarWC):not(#toolbarCC) {
            background: #eee;
            color: #333;
            cursor: pointer;
            transition: background 300ms ease-in-out;

            &:disable {
                color: #7f7f7f;
            }
        }

        &:active:not(#toolbarWC):not(#toolbarCC) {
            transition: background 100ms ease-in-out;
            background: #ddd;
            color: #333;
            cursor: pointer;
        }

        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-left: 1px solid #ddd;
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-right: 1px solid #ddd;
        }
    }
}