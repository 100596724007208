md-bottom-sheet {
    &.md-default-theme.md-hue-3.md-list md-list-item, &.md-hue-3.md-list md-list-item {
        color: rgba(0, 0, 0, 0.87);
    }
}

.md-button {
    &.md-default-theme.md-hue-3.md-primary, &.md-hue-3.md-primary {
        color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3.md-primary.md-fab, &.md-hue-3.md-primary.md-fab, &.md-default-theme.md-hue-3.md-primary.md-raised, &.md-hue-3.md-primary.md-raised {
        color: rgb(255, 255, 255);
        background-color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3.md-primary.md-fab:not([disabled]) md-icon, &.md-hue-3.md-primary.md-fab:not([disabled]) md-icon, &.md-default-theme.md-hue-3.md-primary.md-raised:not([disabled]) md-icon, &.md-hue-3.md-primary.md-raised:not([disabled]) md-icon {
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-hue-3.md-primary.md-fab:not([disabled]).md-focused, &.md-hue-3.md-primary.md-fab:not([disabled]).md-focused, &.md-default-theme.md-hue-3.md-primary.md-fab:not([disabled]):hover, &.md-hue-3.md-primary.md-fab:not([disabled]):hover, &.md-default-theme.md-hue-3.md-primary.md-raised:not([disabled]).md-focused, &.md-hue-3.md-primary.md-raised:not([disabled]).md-focused, &.md-default-theme.md-hue-3.md-primary.md-raised:not([disabled]):hover, &.md-hue-3.md-primary.md-raised:not([disabled]):hover {
        background-color: rgb(53, 58, 72);
    }

    &.md-default-theme.md-hue-3.md-primary:not([disabled]) md-icon, &.md-hue-3.md-primary:not([disabled]) md-icon {
        color: rgb(92, 97, 111);
    }
}

._md a {
    &.md-default-theme.md-hue-3:not(.md-button).md-primary, &.md-hue-3:not(.md-button).md-primary {
        color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3:not(.md-button).md-primary:hover, &.md-hue-3:not(.md-button).md-primary:hover {
        color: rgb(45, 50, 62);
    }
}

md-card {
    &.md-default-theme.md-hue-3 .md-card-image, &.md-hue-3 .md-card-image {
        border-radius: 2px 2px 0 0;
    }

    &.md-default-theme.md-hue-3 md-card-header md-card-header-text .md-subhead, &.md-hue-3 md-card-header md-card-header-text .md-subhead, &.md-default-theme.md-hue-3 md-card-title md-card-title-text:not(:only-child) .md-subhead, &.md-hue-3 md-card-title md-card-title-text:not(:only-child) .md-subhead {
        color: rgba(0, 0, 0, 0.54);
    }
}

md-checkbox {
    &.md-default-theme.md-hue-3 .md-ink-ripple, &.md-hue-3 .md-ink-ripple {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-3:not(.md-checked) .md-icon, &.md-hue-3:not(.md-checked) .md-icon {
        border-color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-3:not([disabled]).md-primary .md-ripple, &.md-hue-3:not([disabled]).md-primary .md-ripple {
        color: rgb(53, 58, 72);
    }

    &.md-default-theme.md-hue-3:not([disabled]).md-primary.md-checked .md-ripple, &.md-hue-3:not([disabled]).md-primary.md-checked .md-ripple {
        color: rgb(117, 117, 117);
    }

    &.md-default-theme.md-hue-3:not([disabled]).md-primary .md-ink-ripple, &.md-hue-3:not([disabled]).md-primary .md-ink-ripple {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-3:not([disabled]).md-primary.md-checked .md-ink-ripple, &.md-hue-3:not([disabled]).md-primary.md-checked .md-ink-ripple {
        color: rgba(92, 97, 111, 0.87);
    }

    &.md-default-theme.md-hue-3:not([disabled]).md-primary:not(.md-checked) .md-icon, &.md-hue-3:not([disabled]).md-primary:not(.md-checked) .md-icon {
        border-color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-3:not([disabled]).md-primary.md-checked .md-icon, &.md-hue-3:not([disabled]).md-primary.md-checked .md-icon {
        background-color: rgba(92, 97, 111, 0.87);
    }

    &.md-default-theme.md-hue-3:not([disabled]).md-primary.md-checked.md-focused .md-container:before, &.md-hue-3:not([disabled]).md-primary.md-checked.md-focused .md-container:before {
        background-color: rgba(92, 97, 111, 0.26);
    }

    &.md-default-theme.md-hue-3:not([disabled]).md-primary.md-checked .md-icon:after, &.md-hue-3:not([disabled]).md-primary.md-checked .md-icon:after {
        border-color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme.md-hue-3:not([disabled]).md-primary .md-indeterminate[disabled] .md-container, &.md-hue-3:not([disabled]).md-primary .md-indeterminate[disabled] .md-container {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-hue-3[disabled]:not(.md-checked) .md-icon, &.md-hue-3[disabled]:not(.md-checked) .md-icon, &.md-default-theme.md-hue-3[disabled] .md-icon:after, &.md-hue-3[disabled] .md-icon:after {
        border-color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-hue-3[disabled] .md-label, &.md-hue-3[disabled] .md-label {
        color: rgba(0, 0, 0, 0.38);
    }
}

md-chips {
    &.md-default-theme.md-hue-3 .md-chips, &.md-hue-3 .md-chips {
        box-shadow: 0 1px rgba(0, 0, 0, 0.12);
    }

    &.md-default-theme.md-hue-3 .md-chips.md-focused, &.md-hue-3 .md-chips.md-focused {
        box-shadow: 0 2px rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3 .md-chips .md-chip-input-container input, &.md-hue-3 .md-chips .md-chip-input-container input {
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme.md-hue-3 .md-chips .md-chip-input-container input:-moz-placeholder, &.md-hue-3 .md-chips .md-chip-input-container input:-moz-placeholder, &.md-default-theme.md-hue-3 .md-chips .md-chip-input-container input::-moz-placeholder, &.md-hue-3 .md-chips .md-chip-input-container input::-moz-placeholder, &.md-default-theme.md-hue-3 .md-chips .md-chip-input-container input:-ms-input-placeholder, &.md-hue-3 .md-chips .md-chip-input-container input:-ms-input-placeholder, &.md-default-theme.md-hue-3 .md-chips .md-chip-input-container input::-webkit-input-placeholder, &.md-hue-3 .md-chips .md-chip-input-container input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-hue-3 md-chip.md-focused, &.md-hue-3 md-chip.md-focused {
        background: rgb(92, 97, 111);
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-hue-3 md-chip.md-focused md-icon, &.md-hue-3 md-chip.md-focused md-icon {
        color: rgb(255, 255, 255);
    }
}

.md-default-theme.md-hue-3 .md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator, .md-hue-3 .md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator {
    border: 1px solid rgb(60, 66, 82);
}

.md-default-theme.md-hue-3 .md-calendar-date.md-calendar-date-today.md-calendar-date-disabled, .md-hue-3 .md-calendar-date.md-calendar-date-today.md-calendar-date-disabled {
    color: rgba(60, 66, 82, 0.6);
}

.md-default-theme.md-hue-3 .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator, .md-hue-3 .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator, .md-default-theme.md-hue-3 .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator, .md-hue-3 .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator {
    background: rgb(60, 66, 82);
    color: rgba(255, 255, 255, 0.87);
    border-color: transparent;
}

.md-default-theme.md-hue-3 .md-datepicker-input, .md-hue-3 .md-datepicker-input {
    color: rgba(0, 0, 0, 0.87);
}

.md-default-theme.md-hue-3 .md-datepicker-input:-moz-placeholder, .md-hue-3 .md-datepicker-input:-moz-placeholder, .md-default-theme.md-hue-3 .md-datepicker-input::-moz-placeholder, .md-hue-3 .md-datepicker-input::-moz-placeholder, .md-default-theme.md-hue-3 .md-datepicker-input:-ms-input-placeholder, .md-hue-3 .md-datepicker-input:-ms-input-placeholder, .md-default-theme.md-hue-3 .md-datepicker-input::-webkit-input-placeholder, .md-hue-3 .md-datepicker-input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
}

.md-default-theme.md-hue-3 .md-datepicker-input-container, .md-hue-3 .md-datepicker-input-container {
    border-bottom-color: rgba(0, 0, 0, 0.12);
}

.md-default-theme.md-hue-3 .md-datepicker-input-container.md-datepicker-focused, .md-hue-3 .md-datepicker-input-container.md-datepicker-focused {
    border-bottom-color: rgb(92, 97, 111);
}

.md-default-theme.md-hue-3 .md-datepicker-triangle-button .md-datepicker-expand-triangle, .md-hue-3 .md-datepicker-triangle-button .md-datepicker-expand-triangle {
    border-top-color: rgba(0, 0, 0, 0.54);
}

.md-default-theme.md-hue-3 .md-datepicker-open .md-datepicker-calendar-icon, .md-hue-3 .md-datepicker-open .md-datepicker-calendar-icon {
    color: rgb(92, 97, 111);
}

md-dialog {
    &.md-default-theme.md-hue-3.md-content-overflow .md-actions, &.md-hue-3.md-content-overflow .md-actions, &.md-default-theme.md-hue-3.md-content-overflow md-dialog-actions, &.md-hue-3.md-content-overflow md-dialog-actions {
        border-top-color: rgba(0, 0, 0, 0.12);
    }
}

md-divider {
    &.md-default-theme.md-hue-3, &.md-hue-3 {
        border-top-color: rgba(0, 0, 0, 0.12);
    }
}

.layout-gt-lg-row > md-divider {
    &.md-default-theme.md-hue-3, &.md-hue-3 {
        border-right-color: rgba(0, 0, 0, 0.12);
    }
}

.layout-gt-md-row > md-divider {
    &.md-default-theme.md-hue-3, &.md-hue-3 {
        border-right-color: rgba(0, 0, 0, 0.12);
    }
}

.layout-gt-sm-row > md-divider {
    &.md-default-theme.md-hue-3, &.md-hue-3 {
        border-right-color: rgba(0, 0, 0, 0.12);
    }
}

.layout-gt-xs-row > md-divider {
    &.md-default-theme.md-hue-3, &.md-hue-3 {
        border-right-color: rgba(0, 0, 0, 0.12);
    }
}

.layout-lg-row > md-divider {
    &.md-default-theme.md-hue-3, &.md-hue-3 {
        border-right-color: rgba(0, 0, 0, 0.12);
    }
}

.layout-md-row > md-divider {
    &.md-default-theme.md-hue-3, &.md-hue-3 {
        border-right-color: rgba(0, 0, 0, 0.12);
    }
}

.layout-row > md-divider {
    &.md-default-theme.md-hue-3, &.md-hue-3 {
        border-right-color: rgba(0, 0, 0, 0.12);
    }
}

.layout-sm-row > md-divider {
    &.md-default-theme.md-hue-3, &.md-hue-3 {
        border-right-color: rgba(0, 0, 0, 0.12);
    }
}

.layout-xl-row > md-divider {
    &.md-default-theme.md-hue-3, &.md-hue-3 {
        border-right-color: rgba(0, 0, 0, 0.12);
    }
}

.layout-xs-row > md-divider {
    &.md-default-theme.md-hue-3, &.md-hue-3 {
        border-right-color: rgba(0, 0, 0, 0.12);
    }
}

md-icon {
    &.md-default-theme.md-hue-3, &.md-hue-3 {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-3.md-primary, &.md-hue-3.md-primary {
        color: rgb(92, 97, 111);
    }
}

md-input-container {
    &.md-default-theme.md-hue-3 .md-input, &.md-hue-3 .md-input {
        color: rgba(0, 0, 0, 0.87);
        border-color: rgba(0, 0, 0, 0.12);
    }

    &.md-default-theme.md-hue-3 .md-input:-moz-placeholder, &.md-hue-3 .md-input:-moz-placeholder, &.md-default-theme.md-hue-3 .md-input::-moz-placeholder, &.md-hue-3 .md-input::-moz-placeholder, &.md-default-theme.md-hue-3 .md-input:-ms-input-placeholder, &.md-hue-3 .md-input:-ms-input-placeholder, &.md-default-theme.md-hue-3 .md-input::-webkit-input-placeholder, &.md-hue-3 .md-input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-hue-3 > md-icon, &.md-hue-3 > md-icon {
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme.md-hue-3 .md-placeholder, &.md-hue-3 .md-placeholder, &.md-default-theme.md-hue-3 label, &.md-hue-3 label {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-hue-3:not(.md-input-focused):not(.md-input-invalid) label.md-required:after, &.md-hue-3:not(.md-input-focused):not(.md-input-invalid) label.md-required:after {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-3 .md-input-message-animation .md-char-counter, &.md-hue-3 .md-input-message-animation .md-char-counter, &.md-default-theme.md-hue-3 .md-input-messages-animation .md-char-counter, &.md-hue-3 .md-input-messages-animation .md-char-counter {
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme.md-hue-3.md-input-focused .md-input:-moz-placeholder, &.md-hue-3.md-input-focused .md-input:-moz-placeholder, &.md-default-theme.md-hue-3.md-input-focused .md-input::-moz-placeholder, &.md-hue-3.md-input-focused .md-input::-moz-placeholder, &.md-default-theme.md-hue-3.md-input-focused .md-input:-ms-input-placeholder, &.md-hue-3.md-input-focused .md-input:-ms-input-placeholder, &.md-default-theme.md-hue-3.md-input-focused .md-input::-webkit-input-placeholder, &.md-hue-3.md-input-focused .md-input::-webkit-input-placeholder, &.md-default-theme.md-hue-3:not(.md-input-invalid).md-input-has-value label, &.md-hue-3:not(.md-input-invalid).md-input-has-value label {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-3:not(.md-input-invalid).md-input-focused .md-input, &.md-hue-3:not(.md-input-invalid).md-input-focused .md-input, &.md-default-theme.md-hue-3:not(.md-input-invalid).md-input-resized .md-input, &.md-hue-3:not(.md-input-invalid).md-input-resized .md-input {
        border-color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3:not(.md-input-invalid).md-input-focused label, &.md-hue-3:not(.md-input-invalid).md-input-focused label, &.md-default-theme.md-hue-3:not(.md-input-invalid).md-input-focused md-icon, &.md-hue-3:not(.md-input-invalid).md-input-focused md-icon {
        color: rgb(92, 97, 111);
    }
}

md-list {
    &.md-default-theme.md-hue-3 md-list-item.md-2-line .md-list-item-text h3, &.md-hue-3 md-list-item.md-2-line .md-list-item-text h3, &.md-default-theme.md-hue-3 md-list-item.md-2-line .md-list-item-text h4, &.md-hue-3 md-list-item.md-2-line .md-list-item-text h4, &.md-default-theme.md-hue-3 md-list-item.md-3-line .md-list-item-text h3, &.md-hue-3 md-list-item.md-3-line .md-list-item-text h3, &.md-default-theme.md-hue-3 md-list-item.md-3-line .md-list-item-text h4, &.md-hue-3 md-list-item.md-3-line .md-list-item-text h4 {
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme.md-hue-3 md-list-item.md-2-line .md-list-item-text p, &.md-hue-3 md-list-item.md-2-line .md-list-item-text p, &.md-default-theme.md-hue-3 md-list-item.md-3-line .md-list-item-text p, &.md-hue-3 md-list-item.md-3-line .md-list-item-text p, &.md-default-theme.md-hue-3 md-list-item > md-icon, &.md-hue-3 md-list-item > md-icon {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-3 md-list-item > md-icon.md-highlight, &.md-hue-3 md-list-item > md-icon.md-highlight {
        color: rgb(92, 97, 111);
    }
}

md-menu-bar {
    &.md-default-theme.md-hue-3 > button.md-button, &.md-hue-3 > button.md-button {
        color: rgba(0, 0, 0, 0.54);
        border-radius: 2px;
    }
}

md-toolbar {
    &.md-default-theme.md-hue-3.md-menu-toolbar md-toolbar-filler, &.md-hue-3.md-menu-toolbar md-toolbar-filler {
        background-color: rgb(92, 97, 111);
        color: rgba(255, 255, 255, 0.87);
    }
}

md-nav-bar {
    &.md-default-theme.md-hue-3 .md-button._md-nav-button.md-unselected, &.md-hue-3 .md-button._md-nav-button.md-unselected {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-3.md-primary > .md-nav-bar, &.md-hue-3.md-primary > .md-nav-bar {
        background-color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3.md-primary > .md-nav-bar .md-button._md-nav-button, &.md-hue-3.md-primary > .md-nav-bar .md-button._md-nav-button {
        color: rgb(197, 198, 203);
    }

    &.md-default-theme.md-hue-3.md-primary > .md-nav-bar .md-button._md-nav-button.md-active, &.md-hue-3.md-primary > .md-nav-bar .md-button._md-nav-button.md-active, &.md-default-theme.md-hue-3.md-primary > .md-nav-bar .md-button._md-nav-button.md-focused, &.md-hue-3.md-primary > .md-nav-bar .md-button._md-nav-button.md-focused {
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-hue-3.md-primary > .md-nav-bar .md-button._md-nav-button.md-focused, &.md-hue-3.md-primary > .md-nav-bar .md-button._md-nav-button.md-focused {
        background: rgba(255, 255, 255, 0.1);
    }
}

md-toolbar > md-nav-bar {
    &.md-default-theme.md-hue-3 > .md-nav-bar, &.md-hue-3 > .md-nav-bar {
        background-color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3 > .md-nav-bar .md-button._md-nav-button, &.md-hue-3 > .md-nav-bar .md-button._md-nav-button {
        color: rgb(197, 198, 203);
    }

    &.md-default-theme.md-hue-3 > .md-nav-bar .md-button._md-nav-button.md-active, &.md-hue-3 > .md-nav-bar .md-button._md-nav-button.md-active, &.md-default-theme.md-hue-3 > .md-nav-bar .md-button._md-nav-button.md-focused, &.md-hue-3 > .md-nav-bar .md-button._md-nav-button.md-focused {
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-hue-3 > .md-nav-bar .md-button._md-nav-button.md-focused, &.md-hue-3 > .md-nav-bar .md-button._md-nav-button.md-focused {
        background: rgba(255, 255, 255, 0.1);
    }
}

md-progress-circular {
    &.md-default-theme.md-hue-3 path, &.md-hue-3 path {
        stroke: rgb(92, 97, 111);
    }
}

md-progress-linear {
    &.md-default-theme.md-hue-3 .md-container, &.md-hue-3 .md-container {
        background-color: rgb(197, 198, 203);
    }

    &.md-default-theme.md-hue-3 .md-bar, &.md-hue-3 .md-bar {
        background-color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3[md-mode=buffer].md-primary .md-bar1, &.md-hue-3[md-mode=buffer].md-primary .md-bar1 {
        background-color: rgb(197, 198, 203);
    }

    &.md-default-theme.md-hue-3[md-mode=buffer].md-primary .md-dashed:before, &.md-hue-3[md-mode=buffer].md-primary .md-dashed:before {
        background: radial-gradient(rgb(197, 198, 203) 0, rgb(197, 198, 203) 16%, transparent 42%);
    }
}

md-radio-button {
    &.md-default-theme.md-hue-3 .md-off, &.md-hue-3 .md-off {
        border-color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-3:not([disabled]).md-primary .md-on, &.md-hue-3:not([disabled]).md-primary .md-on, &.md-default-theme.md-hue-3:not([disabled]) .md-primary .md-on, &.md-hue-3:not([disabled]) .md-primary .md-on {
        background-color: rgba(92, 97, 111, 0.87);
    }
}

md-radio-group {
    &.md-default-theme.md-hue-3:not([disabled]).md-primary .md-on, &.md-hue-3:not([disabled]).md-primary .md-on, &.md-default-theme.md-hue-3:not([disabled]) .md-primary .md-on, &.md-hue-3:not([disabled]) .md-primary .md-on {
        background-color: rgba(92, 97, 111, 0.87);
    }
}

md-radio-button {
    &.md-default-theme.md-hue-3:not([disabled]).md-primary.md-checked .md-off, &.md-hue-3:not([disabled]).md-primary.md-checked .md-off, &.md-default-theme.md-hue-3:not([disabled]) .md-primary.md-checked .md-off, &.md-hue-3:not([disabled]) .md-primary.md-checked .md-off, &.md-default-theme.md-hue-3:not([disabled]).md-primary .md-checked .md-off, &.md-hue-3:not([disabled]).md-primary .md-checked .md-off, &.md-default-theme.md-hue-3:not([disabled]) .md-primary .md-checked .md-off, &.md-hue-3:not([disabled]) .md-primary .md-checked .md-off {
        border-color: rgba(92, 97, 111, 0.87);
    }
}

md-radio-group {
    &.md-default-theme.md-hue-3:not([disabled]).md-primary.md-checked .md-off, &.md-hue-3:not([disabled]).md-primary.md-checked .md-off, &.md-default-theme.md-hue-3:not([disabled]) .md-primary.md-checked .md-off, &.md-hue-3:not([disabled]) .md-primary.md-checked .md-off, &.md-default-theme.md-hue-3:not([disabled]).md-primary .md-checked .md-off, &.md-hue-3:not([disabled]).md-primary .md-checked .md-off, &.md-default-theme.md-hue-3:not([disabled]) .md-primary .md-checked .md-off, &.md-hue-3:not([disabled]) .md-primary .md-checked .md-off {
        border-color: rgba(92, 97, 111, 0.87);
    }
}

md-radio-button {
    &.md-default-theme.md-hue-3:not([disabled]).md-primary.md-checked .md-ink-ripple, &.md-hue-3:not([disabled]).md-primary.md-checked .md-ink-ripple, &.md-default-theme.md-hue-3:not([disabled]) .md-primary.md-checked .md-ink-ripple, &.md-hue-3:not([disabled]) .md-primary.md-checked .md-ink-ripple, &.md-default-theme.md-hue-3:not([disabled]).md-primary .md-checked .md-ink-ripple, &.md-hue-3:not([disabled]).md-primary .md-checked .md-ink-ripple, &.md-default-theme.md-hue-3:not([disabled]) .md-primary .md-checked .md-ink-ripple, &.md-hue-3:not([disabled]) .md-primary .md-checked .md-ink-ripple {
        color: rgba(92, 97, 111, 0.87);
    }
}

md-radio-group {
    &.md-default-theme.md-hue-3:not([disabled]).md-primary.md-checked .md-ink-ripple, &.md-hue-3:not([disabled]).md-primary.md-checked .md-ink-ripple, &.md-default-theme.md-hue-3:not([disabled]) .md-primary.md-checked .md-ink-ripple, &.md-hue-3:not([disabled]) .md-primary.md-checked .md-ink-ripple, &.md-default-theme.md-hue-3:not([disabled]).md-primary .md-checked .md-ink-ripple, &.md-hue-3:not([disabled]).md-primary .md-checked .md-ink-ripple, &.md-default-theme.md-hue-3:not([disabled]) .md-primary .md-checked .md-ink-ripple, &.md-hue-3:not([disabled]) .md-primary .md-checked .md-ink-ripple {
        color: rgba(92, 97, 111, 0.87);
    }
}

md-radio-button {
    &.md-default-theme.md-hue-3:not([disabled]).md-primary .md-container .md-ripple, &.md-hue-3:not([disabled]).md-primary .md-container .md-ripple, &.md-default-theme.md-hue-3:not([disabled]) .md-primary .md-container .md-ripple, &.md-hue-3:not([disabled]) .md-primary .md-container .md-ripple {
        color: rgb(53, 58, 72);
    }
}

md-radio-group {
    &.md-default-theme.md-hue-3:not([disabled]).md-primary .md-container .md-ripple, &.md-hue-3:not([disabled]).md-primary .md-container .md-ripple, &.md-default-theme.md-hue-3:not([disabled]) .md-primary .md-container .md-ripple, &.md-hue-3:not([disabled]) .md-primary .md-container .md-ripple {
        color: rgb(53, 58, 72);
    }
}

md-radio-button {
    &.md-default-theme.md-hue-3[disabled], &.md-hue-3[disabled] {
        color: rgba(0, 0, 0, 0.38);
    }
}

md-radio-group {
    &.md-default-theme.md-hue-3[disabled], &.md-hue-3[disabled] {
        color: rgba(0, 0, 0, 0.38);
    }
}

md-radio-button {
    &.md-default-theme.md-hue-3[disabled] .md-container .md-off, &.md-hue-3[disabled] .md-container .md-off, &.md-default-theme.md-hue-3[disabled] .md-container .md-on, &.md-hue-3[disabled] .md-container .md-on {
        border-color: rgba(0, 0, 0, 0.38);
    }
}

md-radio-group {
    &.md-default-theme.md-hue-3[disabled] .md-container .md-off, &.md-hue-3[disabled] .md-container .md-off, &.md-default-theme.md-hue-3[disabled] .md-container .md-on, &.md-hue-3[disabled] .md-container .md-on {
        border-color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-hue-3 .md-checked:not([disabled]).md-primary .md-ink-ripple, &.md-hue-3 .md-checked:not([disabled]).md-primary .md-ink-ripple, &.md-default-theme.md-hue-3.md-primary .md-checked:not([disabled]) .md-ink-ripple, &.md-hue-3.md-primary .md-checked:not([disabled]) .md-ink-ripple {
        color: rgba(92, 97, 111, 0.26);
    }

    &.md-default-theme.md-hue-3.md-focused:not(:empty) .md-checked.md-primary .md-container:before, &.md-hue-3.md-focused:not(:empty) .md-checked.md-primary .md-container:before, &.md-default-theme.md-hue-3.md-focused:not(:empty).md-primary .md-checked .md-container:before, &.md-hue-3.md-focused:not(:empty).md-primary .md-checked .md-container:before {
        background-color: rgba(92, 97, 111, 0.26);
    }
}

md-input-container {
    &:not(.md-input-focused):not(.md-input-invalid) md-select {
        &.md-default-theme.md-hue-3 .md-select-value span:first-child:after, &.md-hue-3 .md-select-value span:first-child:after {
            color: rgba(0, 0, 0, 0.38);
        }
    }

    &.md-input-focused:not(.md-input-has-value) md-select {
        &.md-default-theme.md-hue-3 .md-select-value, &.md-hue-3 .md-select-value, &.md-default-theme.md-hue-3 .md-select-value.md-select-placeholder, &.md-hue-3 .md-select-value.md-select-placeholder {
            color: rgb(92, 97, 111);
        }
    }

    &.md-input-invalid md-select {
        &.md-default-theme.md-hue-3.md-no-underline .md-select-value, &.md-hue-3.md-no-underline .md-select-value {
            border-bottom-color: transparent !important;
        }
    }
}

md-select {
    &.md-default-theme.md-hue-3 .md-select-value, &.md-hue-3 .md-select-value {
        border-bottom-color: rgba(0, 0, 0, 0.12);
    }

    &.md-default-theme.md-hue-3 .md-select-value.md-select-placeholder, &.md-hue-3 .md-select-value.md-select-placeholder {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-hue-3.md-no-underline .md-select-value, &.md-hue-3.md-no-underline .md-select-value, &.md-default-theme.md-hue-3.ng-invalid.ng-touched.md-no-underline .md-select-value, &.md-hue-3.ng-invalid.ng-touched.md-no-underline .md-select-value {
        border-bottom-color: transparent !important;
    }

    &.md-default-theme.md-hue-3:not([disabled]):focus .md-select-value, &.md-hue-3:not([disabled]):focus .md-select-value {
        border-bottom-color: rgb(92, 97, 111);
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme.md-hue-3:not([disabled]):focus .md-select-value.md-select-placeholder, &.md-hue-3:not([disabled]):focus .md-select-value.md-select-placeholder {
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme.md-hue-3:not([disabled]):focus.md-no-underline .md-select-value, &.md-hue-3:not([disabled]):focus.md-no-underline .md-select-value {
        border-bottom-color: transparent !important;
    }

    &.md-default-theme.md-hue-3[disabled] .md-select-icon, &.md-hue-3[disabled] .md-select-icon, &.md-default-theme.md-hue-3[disabled] .md-select-value, &.md-hue-3[disabled] .md-select-value, &.md-default-theme.md-hue-3[disabled] .md-select-value.md-select-placeholder, &.md-hue-3[disabled] .md-select-value.md-select-placeholder {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-hue-3 .md-select-icon, &.md-hue-3 .md-select-icon {
        color: rgba(0, 0, 0, 0.54);
    }
}

md-select-menu {
    &.md-default-theme.md-hue-3 md-content md-option[selected], &.md-hue-3 md-content md-option[selected] {
        color: rgb(60, 66, 82);
    }

    &.md-default-theme.md-hue-3 md-content md-option[selected]:focus, &.md-hue-3 md-content md-option[selected]:focus {
        color: rgb(53, 58, 72);
    }
}

.md-checkbox-enabled {
    &.md-default-theme.md-hue-3 .md-ripple, &.md-hue-3 .md-ripple {
        color: rgb(53, 58, 72);
    }

    &.md-default-theme.md-hue-3 .md-ink-ripple, &.md-hue-3 .md-ink-ripple {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-3[selected] .md-ink-ripple, &.md-hue-3[selected] .md-ink-ripple {
        color: rgba(92, 97, 111, 0.87);
    }

    &.md-default-theme.md-hue-3:not(.md-checked) .md-icon, &.md-hue-3:not(.md-checked) .md-icon {
        border-color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-3[selected] .md-icon, &.md-hue-3[selected] .md-icon {
        background-color: rgba(92, 97, 111, 0.87);
    }

    &.md-default-theme.md-hue-3[selected].md-focused .md-container:before, &.md-hue-3[selected].md-focused .md-container:before {
        background-color: rgba(92, 97, 111, 0.26);
    }

    &.md-default-theme.md-hue-3[selected] .md-icon:after, &.md-hue-3[selected] .md-icon:after {
        border-color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme.md-hue-3 .md-indeterminate[disabled] .md-container, &.md-hue-3 .md-indeterminate[disabled] .md-container {
        color: rgba(0, 0, 0, 0.38);
    }
}

md-slider {
    &.md-default-theme.md-hue-3.md-primary .md-focus-ring, &.md-hue-3.md-primary .md-focus-ring {
        background-color: rgba(158, 161, 169, 0.38);
    }

    &.md-default-theme.md-hue-3.md-primary .md-track.md-track-fill, &.md-hue-3.md-primary .md-track.md-track-fill {
        background-color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3.md-primary .md-thumb:after, &.md-hue-3.md-primary .md-thumb:after {
        border-color: rgb(92, 97, 111);
        background-color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3.md-primary .md-sign, &.md-hue-3.md-primary .md-sign {
        background-color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3.md-primary .md-sign:after, &.md-hue-3.md-primary .md-sign:after {
        border-top-color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3.md-primary[md-vertical] .md-sign:after, &.md-hue-3.md-primary[md-vertical] .md-sign:after {
        border-top-color: transparent;
        border-left-color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3.md-primary .md-thumb-text, &.md-hue-3.md-primary .md-thumb-text {
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-hue-3[disabled] .md-thumb:after, &.md-hue-3[disabled] .md-thumb:after {
        border-color: transparent;
    }
}

md-slider-container[disabled] > {
    :first-child:not(md-slider), :last-child:not(md-slider) {
        color: rgba(0, 0, 0, 0.38);
    }
}

.md-subheader {
    &.md-default-theme.md-hue-3.md-primary, &.md-hue-3.md-primary {
        color: rgb(92, 97, 111);
    }
}

md-switch {
    &.md-default-theme.md-hue-3.md-checked.md-primary .md-ink-ripple, &.md-hue-3.md-checked.md-primary .md-ink-ripple {
        color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3.md-checked.md-primary .md-thumb, &.md-hue-3.md-checked.md-primary .md-thumb {
        background-color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3.md-checked.md-primary .md-bar, &.md-hue-3.md-checked.md-primary .md-bar {
        background-color: rgba(92, 97, 111, 0.5);
    }

    &.md-default-theme.md-hue-3.md-checked.md-primary.md-focused .md-thumb:before, &.md-hue-3.md-checked.md-primary.md-focused .md-thumb:before {
        background-color: rgba(92, 97, 111, 0.26);
    }
}

md-tabs {
    &.md-default-theme.md-hue-3 .md-paginator md-icon, &.md-hue-3 .md-paginator md-icon {
        color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3 .md-tab, &.md-hue-3 .md-tab {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-3 .md-tab[disabled], &.md-hue-3 .md-tab[disabled], &.md-default-theme.md-hue-3 .md-tab[disabled] md-icon, &.md-hue-3 .md-tab[disabled] md-icon {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-hue-3 .md-tab.md-active, &.md-hue-3 .md-tab.md-active, &.md-default-theme.md-hue-3 .md-tab.md-active md-icon, &.md-hue-3 .md-tab.md-active md-icon, &.md-default-theme.md-hue-3 .md-tab.md-focused, &.md-hue-3 .md-tab.md-focused, &.md-default-theme.md-hue-3 .md-tab.md-focused md-icon, &.md-hue-3 .md-tab.md-focused md-icon {
        color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3 .md-tab.md-focused, &.md-hue-3 .md-tab.md-focused {
        background: rgba(92, 97, 111, 0.1);
    }

    &.md-default-theme.md-hue-3.md-primary > md-tabs-wrapper, &.md-hue-3.md-primary > md-tabs-wrapper {
        background-color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), &.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), &.md-default-theme.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon, &.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
        color: rgb(197, 198, 203);
    }

    &.md-default-theme.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, &.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, &.md-default-theme.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, &.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, &.md-default-theme.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-default-theme.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon, &.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-hue-3.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
        background: rgba(255, 255, 255, 0.1);
    }
}

md-toolbar > md-tabs {
    &.md-default-theme.md-hue-3 > md-tabs-wrapper, &.md-hue-3 > md-tabs-wrapper {
        background-color: rgb(92, 97, 111);
    }

    &.md-default-theme.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), &.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), &.md-default-theme.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon, &.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
        color: rgb(197, 198, 203);
    }

    &.md-default-theme.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, &.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, &.md-default-theme.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, &.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, &.md-default-theme.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-default-theme.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon, &.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-hue-3 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
        background: rgba(255, 255, 255, 0.1);
    }
}

md-toast {
    &.md-default-theme.md-hue-3 .md-toast-content .md-button.md-highlight.md-primary, &.md-hue-3 .md-toast-content .md-button.md-highlight.md-primary {
        color: rgb(92, 97, 111);
    }
}

md-toolbar {
    &.md-default-theme.md-hue-3:not(.md-menu-toolbar), &.md-hue-3:not(.md-menu-toolbar) {
        background-color: rgb(92, 97, 111);
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-hue-3:not(.md-menu-toolbar) md-icon, &.md-hue-3:not(.md-menu-toolbar) md-icon {
        color: rgb(255, 255, 255);
        fill: rgb(255, 255, 255);
    }

    &.md-default-theme.md-hue-3:not(.md-menu-toolbar) .md-button[disabled] md-icon, &.md-hue-3:not(.md-menu-toolbar) .md-button[disabled] md-icon {
        color: rgba(255, 255, 255, 0.26);
        fill: rgba(255, 255, 255, 0.26);
    }
}