md-bottom-sheet {
    &.md-default-theme.md-list md-list-item,
    &.md-list md-list-item {
        color: rgba(0, 0, 0, 0.87);
    }
}

.md-button {
    &.md-default-theme.md-primary,
    &.md-primary {
        color: rgb(45, 50, 62);
    }

    &.md-default-theme.md-primary.md-fab,
    &.md-primary.md-fab,
    &.md-default-theme.md-primary.md-raised,
    &.md-primary.md-raised {
        color: rgba(255, 255, 255, 0.87);
        background-color: rgb(45, 50, 62);
    }

    &.md-default-theme.md-primary.md-fab:not([disabled]) md-icon,
    &.md-primary.md-fab:not([disabled]) md-icon,
    &.md-default-theme.md-primary.md-raised:not([disabled]) md-icon,
    &.md-primary.md-raised:not([disabled]) md-icon {
        color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme.md-primary.md-fab:not([disabled]).md-focused,
    &.md-primary.md-fab:not([disabled]).md-focused,
    &.md-default-theme.md-primary.md-fab:not([disabled]):hover,
    &.md-primary.md-fab:not([disabled]):hover,
    &.md-default-theme.md-primary.md-raised:not([disabled]).md-focused,
    &.md-primary.md-raised:not([disabled]).md-focused,
    &.md-default-theme.md-primary.md-raised:not([disabled]):hover,
    &.md-primary.md-raised:not([disabled]):hover {
        background-color: rgb(53, 58, 72);
    }

    &.md-default-theme.md-primary:not([disabled]) md-icon,
    &.md-primary:not([disabled]) md-icon {
        color: rgb(45, 50, 62);
    }
}

._md a {
    &.md-default-theme:not(.md-button).md-primary,
    &:not(.md-button).md-primary,
    &.md-default-theme:not(.md-button).md-primary:hover,
    &:not(.md-button).md-primary:hover {
        color: rgb(45, 50, 62);
    }
}

md-card {
    &.md-default-theme .md-card-image,
    .md-card-image {
        border-radius: 2px 2px 0 0;
    }

    &.md-default-theme md-card-header md-card-header-text .md-subhead,
    md-card-header md-card-header-text .md-subhead,
    &.md-default-theme md-card-title md-card-title-text:not(:only-child) .md-subhead,
    md-card-title md-card-title-text:not(:only-child) .md-subhead {
        color: rgba(0, 0, 0, 0.54);
    }
}

md-checkbox {
    &.md-default-theme .md-ink-ripple,
    .md-ink-ripple {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme:not(.md-checked) .md-icon,
    &:not(.md-checked) .md-icon {
        border-color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme:not([disabled]).md-primary .md-ripple,
    &:not([disabled]).md-primary .md-ripple {
        color: rgb(53, 58, 72);
    }

    &.md-default-theme:not([disabled]).md-primary.md-checked .md-ripple,
    &:not([disabled]).md-primary.md-checked .md-ripple {
        color: rgb(117, 117, 117);
    }

    &.md-default-theme:not([disabled]).md-primary .md-ink-ripple,
    &:not([disabled]).md-primary .md-ink-ripple {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme:not([disabled]).md-primary.md-checked .md-ink-ripple,
    &:not([disabled]).md-primary.md-checked .md-ink-ripple {
        color: rgba(45, 50, 62, 0.87);
    }

    &.md-default-theme:not([disabled]).md-primary:not(.md-checked) .md-icon,
    &:not([disabled]).md-primary:not(.md-checked) .md-icon {
        border-color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme:not([disabled]).md-primary.md-checked .md-icon,
    &:not([disabled]).md-primary.md-checked .md-icon {
        background-color: rgba(45, 50, 62, 0.87);
    }

    &.md-default-theme:not([disabled]).md-primary.md-checked.md-focused .md-container:before,
    &:not([disabled]).md-primary.md-checked.md-focused .md-container:before {
        background-color: rgba(45, 50, 62, 0.26);
    }

    &.md-default-theme:not([disabled]).md-primary.md-checked .md-icon:after,
    &:not([disabled]).md-primary.md-checked .md-icon:after {
        border-color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme:not([disabled]).md-primary .md-indeterminate[disabled] .md-container,
    &:not([disabled]).md-primary .md-indeterminate[disabled] .md-container {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme[disabled]:not(.md-checked) .md-icon,
    &[disabled]:not(.md-checked) .md-icon,
    &.md-default-theme[disabled] .md-icon:after,
    &[disabled] .md-icon:after {
        border-color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme[disabled] .md-label,
    &[disabled] .md-label {
        color: rgba(0, 0, 0, 0.38);
    }
}

md-chips {
    &.md-default-theme .md-chips,
    .md-chips {
        box-shadow: 0 1px rgba(0, 0, 0, 0.12);
    }

    &.md-default-theme .md-chips.md-focused,
    .md-chips.md-focused {
        box-shadow: 0 2px rgb(45, 50, 62);
    }

    &.md-default-theme .md-chips .md-chip-input-container input,
    .md-chips .md-chip-input-container input {
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme .md-chips .md-chip-input-container input:-moz-placeholder,
    .md-chips .md-chip-input-container input:-moz-placeholder,
    &.md-default-theme .md-chips .md-chip-input-container input::-moz-placeholder,
    .md-chips .md-chip-input-container input::-moz-placeholder,
    &.md-default-theme .md-chips .md-chip-input-container input:-ms-input-placeholder,
    .md-chips .md-chip-input-container input:-ms-input-placeholder,
    &.md-default-theme .md-chips .md-chip-input-container input::-webkit-input-placeholder,
    .md-chips .md-chip-input-container input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme md-chip.md-focused,
    md-chip.md-focused {
        background: rgb(45, 50, 62);
        color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme md-chip.md-focused md-icon,
    md-chip.md-focused md-icon {
        color: rgba(255, 255, 255, 0.87);
    }
}

.md-default-theme .md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator,
.md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator {
    border: 1px solid rgb(60, 66, 82);
}

.md-default-theme .md-calendar-date.md-calendar-date-today.md-calendar-date-disabled,
.md-calendar-date.md-calendar-date-today.md-calendar-date-disabled {
    color: rgba(60, 66, 82, 0.6);
}

.md-default-theme .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator,
.md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator,
.md-default-theme .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator,
.md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator {
    background: rgb(60, 66, 82);
    color: rgba(255, 255, 255, 0.87);
    border-color: transparent;
}

.md-default-theme .md-datepicker-input,
.md-datepicker-input {
    color: rgba(0, 0, 0, 0.87);
}

.md-default-theme .md-datepicker-input:-moz-placeholder,
.md-datepicker-input:-moz-placeholder,
.md-default-theme .md-datepicker-input::-moz-placeholder,
.md-datepicker-input::-moz-placeholder,
.md-default-theme .md-datepicker-input:-ms-input-placeholder,
.md-datepicker-input:-ms-input-placeholder,
.md-default-theme .md-datepicker-input::-webkit-input-placeholder,
.md-datepicker-input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
}

.md-default-theme .md-datepicker-input-container,
.md-datepicker-input-container {
    border-bottom-color: rgba(0, 0, 0, 0.12);
}

.md-default-theme .md-datepicker-input-container.md-datepicker-focused,
.md-datepicker-input-container.md-datepicker-focused {
    border-bottom-color: rgb(45, 50, 62);
}

.md-default-theme .md-datepicker-triangle-button .md-datepicker-expand-triangle,
.md-datepicker-triangle-button .md-datepicker-expand-triangle {
    border-top-color: rgba(0, 0, 0, 0.54);
}

.md-default-theme .md-datepicker-open .md-datepicker-calendar-icon,
.md-datepicker-open .md-datepicker-calendar-icon {
    color: rgb(45, 50, 62);
}

md-dialog {
    &.md-default-theme.md-content-overflow .md-actions,
    &.md-content-overflow .md-actions,
    &.md-default-theme.md-content-overflow md-dialog-actions,
    &.md-content-overflow md-dialog-actions {
        border-top-color: rgba(0, 0, 0, 0.12);
    }
}

md-divider {
    &.md-default-theme {
        border-top-color: rgba(0, 0, 0, 0.12);
    }

    border-top-color: rgba(0, 0, 0, 0.12);
}

.layout-gt-lg-row > md-divider,
.layout-gt-md-row > md-divider,
.layout-gt-sm-row > md-divider,
.layout-gt-xs-row > md-divider,
.layout-lg-row > md-divider,
.layout-md-row > md-divider,
.layout-row > md-divider,
.layout-sm-row > md-divider,
.layout-xl-row > md-divider,
.layout-xs-row > md-divider {
    &.md-default-theme {
        border-right-color: rgba(0, 0, 0, 0.12);
    }

    border-right-color: rgba(0, 0, 0, 0.12);
}

md-icon {
    &.md-default-theme {
        color: rgba(0, 0, 0, 0.54);

        &.md-primary {
            color: rgb(45, 50, 62);
        }
    }

    color: rgba(0, 0, 0, 0.54);

    &.md-primary {
        color: rgb(45, 50, 62);
    }
}

md-input-container {
    &.md-default-theme .md-input,
    .md-input {
        color: rgba(0, 0, 0, 0.87);
        border-color: rgba(0, 0, 0, 0.12);
    }

    &.md-default-theme .md-input:-moz-placeholder,
    .md-input:-moz-placeholder,
    &.md-default-theme .md-input::-moz-placeholder,
    .md-input::-moz-placeholder,
    &.md-default-theme .md-input:-ms-input-placeholder,
    .md-input:-ms-input-placeholder,
    &.md-default-theme .md-input::-webkit-input-placeholder,
    .md-input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme {
        > md-icon {
            color: rgba(0, 0, 0, 0.87);
        }

        .md-placeholder {
            color: rgba(0, 0, 0, 0.38);
        }
    }

    > md-icon {
        color: rgba(0, 0, 0, 0.87);
    }

    .md-placeholder,
    &.md-default-theme label,
    label {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme:not(.md-input-focused):not(.md-input-invalid) label.md-required:after,
    &:not(.md-input-focused):not(.md-input-invalid) label.md-required:after {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme .md-input-message-animation .md-char-counter,
    .md-input-message-animation .md-char-counter,
    &.md-default-theme .md-input-messages-animation .md-char-counter,
    .md-input-messages-animation .md-char-counter {
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme.md-input-focused .md-input:-moz-placeholder,
    &.md-input-focused .md-input:-moz-placeholder,
    &.md-default-theme.md-input-focused .md-input::-moz-placeholder,
    &.md-input-focused .md-input::-moz-placeholder,
    &.md-default-theme.md-input-focused .md-input:-ms-input-placeholder,
    &.md-input-focused .md-input:-ms-input-placeholder,
    &.md-default-theme.md-input-focused .md-input::-webkit-input-placeholder,
    &.md-input-focused .md-input::-webkit-input-placeholder,
    &.md-default-theme:not(.md-input-invalid).md-input-has-value label,
    &:not(.md-input-invalid).md-input-has-value label {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme:not(.md-input-invalid).md-input-focused .md-input,
    &:not(.md-input-invalid).md-input-focused .md-input,
    &.md-default-theme:not(.md-input-invalid).md-input-resized .md-input,
    &:not(.md-input-invalid).md-input-resized .md-input {
        border-color: rgb(45, 50, 62);
    }

    &.md-default-theme:not(.md-input-invalid).md-input-focused label,
    &:not(.md-input-invalid).md-input-focused label,
    &.md-default-theme:not(.md-input-invalid).md-input-focused md-icon,
    &:not(.md-input-invalid).md-input-focused md-icon {
        color: rgb(45, 50, 62);
    }
}

md-list {
    &.md-default-theme md-list-item.md-2-line .md-list-item-text h3,
    md-list-item.md-2-line .md-list-item-text h3,
    &.md-default-theme md-list-item.md-2-line .md-list-item-text h4,
    md-list-item.md-2-line .md-list-item-text h4,
    &.md-default-theme md-list-item.md-3-line .md-list-item-text h3,
    md-list-item.md-3-line .md-list-item-text h3,
    &.md-default-theme md-list-item.md-3-line .md-list-item-text h4,
    md-list-item.md-3-line .md-list-item-text h4 {
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme md-list-item.md-2-line .md-list-item-text p,
    md-list-item.md-2-line .md-list-item-text p,
    &.md-default-theme md-list-item.md-3-line .md-list-item-text p,
    md-list-item.md-3-line .md-list-item-text p,
    &.md-default-theme md-list-item > md-icon,
    md-list-item > md-icon {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme md-list-item > md-icon.md-highlight,
    md-list-item > md-icon.md-highlight {
        color: rgb(45, 50, 62);
    }
}

md-menu-bar {
    &.md-default-theme > button.md-button, > button.md-button {
        color: rgba(0, 0, 0, 0.54);
        border-radius: 2px;
    }
}

md-toolbar {
    &.md-default-theme.md-menu-toolbar md-toolbar-filler,
    &.md-menu-toolbar md-toolbar-filler {
        background-color: rgb(45, 50, 62);
        color: rgba(255, 255, 255, 0.87);
    }
}

md-nav-bar {
    &.md-default-theme .md-button._md-nav-button.md-unselected,
    .md-button._md-nav-button.md-unselected {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-primary > .md-nav-bar,
    &.md-primary > .md-nav-bar {
        background-color: rgb(45, 50, 62);
    }

    &.md-default-theme.md-primary > .md-nav-bar .md-button._md-nav-button,
    &.md-primary > .md-nav-bar .md-button._md-nav-button {
        color: rgb(197, 198, 203);
    }

    &.md-default-theme.md-primary > .md-nav-bar .md-button._md-nav-button.md-active,
    &.md-primary > .md-nav-bar .md-button._md-nav-button.md-active,
    &.md-default-theme.md-primary > .md-nav-bar .md-button._md-nav-button.md-focused,
    &.md-primary > .md-nav-bar .md-button._md-nav-button.md-focused {
        color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme.md-primary > .md-nav-bar .md-button._md-nav-button.md-focused,
    &.md-primary > .md-nav-bar .md-button._md-nav-button.md-focused {
        background: rgba(255, 255, 255, 0.1);
    }
}

md-toolbar > md-nav-bar {
    &.md-default-theme {
        > .md-nav-bar {
            background-color: rgb(45, 50, 62);
        }

        > .md-nav-bar .md-button._md-nav-button {
            color: rgb(197, 198, 203);
        }

        > .md-nav-bar .md-button._md-nav-button.md-active, > .md-nav-bar .md-button._md-nav-button.md-focused {
            color: rgba(255, 255, 255, 0.87);
        }

        > .md-nav-bar .md-button._md-nav-button.md-focused {
            background: rgba(255, 255, 255, 0.1);
        }
    }

    > .md-nav-bar {
        background-color: rgb(45, 50, 62);
    }

    > .md-nav-bar .md-button._md-nav-button {
        color: rgb(197, 198, 203);
    }

    > .md-nav-bar .md-button._md-nav-button.md-active, > .md-nav-bar .md-button._md-nav-button.md-focused {
        color: rgba(255, 255, 255, 0.87);
    }

    > .md-nav-bar .md-button._md-nav-button.md-focused {
        background: rgba(255, 255, 255, 0.1);
    }
}

md-progress-circular {
    &.md-default-theme path,
    path {
        stroke: rgb(45, 50, 62);
    }
}

md-progress-linear {
    &.md-default-theme .md-container,
    .md-container {
        background-color: rgb(197, 198, 203);
    }

    &.md-default-theme .md-bar,
    .md-bar {
        background-color: rgb(45, 50, 62);
    }

    &.md-default-theme[md-mode=buffer].md-primary .md-bar1,
    &[md-mode=buffer].md-primary .md-bar1 {
        background-color: rgb(197, 198, 203);
    }

    &.md-default-theme[md-mode=buffer].md-primary .md-dashed:before,
    &[md-mode=buffer].md-primary .md-dashed:before {
        background: radial-gradient(rgb(197, 198, 203) 0, rgb(197, 198, 203) 16%, transparent 42%);
    }
}

md-radio-button {
    &.md-default-theme .md-off,
    .md-off {
        border-color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme:not([disabled]).md-primary .md-on,
    &:not([disabled]).md-primary .md-on,
    &.md-default-theme:not([disabled]) .md-primary .md-on,
    &:not([disabled]) .md-primary .md-on {
        background-color: rgba(45, 50, 62, 0.87);
    }
}

md-radio-group {
    &.md-default-theme:not([disabled]).md-primary .md-on,
    &:not([disabled]).md-primary .md-on,
    &.md-default-theme:not([disabled]) .md-primary .md-on,
    &:not([disabled]) .md-primary .md-on {
        background-color: rgba(45, 50, 62, 0.87);
    }
}

md-radio-button {
    &.md-default-theme:not([disabled]).md-primary.md-checked .md-off,
    &:not([disabled]).md-primary.md-checked .md-off,
    &.md-default-theme:not([disabled]) .md-primary.md-checked .md-off,
    &:not([disabled]) .md-primary.md-checked .md-off,
    &.md-default-theme:not([disabled]).md-primary .md-checked .md-off,
    &:not([disabled]).md-primary .md-checked .md-off,
    &.md-default-theme:not([disabled]) .md-primary .md-checked .md-off,
    &:not([disabled]) .md-primary .md-checked .md-off {
        border-color: rgba(45, 50, 62, 0.87);
    }
}

md-radio-group {
    &.md-default-theme:not([disabled]).md-primary.md-checked .md-off,
    &:not([disabled]).md-primary.md-checked .md-off,
    &.md-default-theme:not([disabled]) .md-primary.md-checked .md-off,
    &:not([disabled]) .md-primary.md-checked .md-off,
    &.md-default-theme:not([disabled]).md-primary .md-checked .md-off,
    &:not([disabled]).md-primary .md-checked .md-off,
    &.md-default-theme:not([disabled]) .md-primary .md-checked .md-off,
    &:not([disabled]) .md-primary .md-checked .md-off {
        border-color: rgba(45, 50, 62, 0.87);
    }
}

md-radio-button {
    &.md-default-theme:not([disabled]).md-primary.md-checked .md-ink-ripple,
    &:not([disabled]).md-primary.md-checked .md-ink-ripple,
    &.md-default-theme:not([disabled]) .md-primary.md-checked .md-ink-ripple,
    &:not([disabled]) .md-primary.md-checked .md-ink-ripple,
    &.md-default-theme:not([disabled]).md-primary .md-checked .md-ink-ripple,
    &:not([disabled]).md-primary .md-checked .md-ink-ripple,
    &.md-default-theme:not([disabled]) .md-primary .md-checked .md-ink-ripple,
    &:not([disabled]) .md-primary .md-checked .md-ink-ripple {
        color: rgba(45, 50, 62, 0.87);
    }
}

md-radio-group {
    &.md-default-theme:not([disabled]).md-primary.md-checked .md-ink-ripple,
    &:not([disabled]).md-primary.md-checked .md-ink-ripple,
    &.md-default-theme:not([disabled]) .md-primary.md-checked .md-ink-ripple,
    &:not([disabled]) .md-primary.md-checked .md-ink-ripple,
    &.md-default-theme:not([disabled]).md-primary .md-checked .md-ink-ripple,
    &:not([disabled]).md-primary .md-checked .md-ink-ripple,
    &.md-default-theme:not([disabled]) .md-primary .md-checked .md-ink-ripple,
    &:not([disabled]) .md-primary .md-checked .md-ink-ripple {
        color: rgba(45, 50, 62, 0.87);
    }
}

md-radio-button {
    &.md-default-theme:not([disabled]).md-primary .md-container .md-ripple,
    &:not([disabled]).md-primary .md-container .md-ripple,
    &.md-default-theme:not([disabled]) .md-primary .md-container .md-ripple,
    &:not([disabled]) .md-primary .md-container .md-ripple {
        color: rgb(53, 58, 72);
    }
}

md-radio-group {
    &.md-default-theme:not([disabled]).md-primary .md-container .md-ripple,
    &:not([disabled]).md-primary .md-container .md-ripple,
    &.md-default-theme:not([disabled]) .md-primary .md-container .md-ripple,
    &:not([disabled]) .md-primary .md-container .md-ripple {
        color: rgb(53, 58, 72);
    }
}

md-radio-button {
    &.md-default-theme[disabled],
    &[disabled] {
        color: rgba(0, 0, 0, 0.38);
    }
}

md-radio-group {
    &.md-default-theme[disabled],
    &[disabled] {
        color: rgba(0, 0, 0, 0.38);
    }
}

md-radio-button {
    &.md-default-theme[disabled] .md-container .md-off,
    &[disabled] .md-container .md-off,
    &.md-default-theme[disabled] .md-container .md-on,
    &[disabled] .md-container .md-on {
        border-color: rgba(0, 0, 0, 0.38);
    }
}

md-radio-group {
    &.md-default-theme[disabled] .md-container .md-off,
    &[disabled] .md-container .md-off,
    &.md-default-theme[disabled] .md-container .md-on,
    &[disabled] .md-container .md-on {
        border-color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme .md-checked:not([disabled]).md-primary .md-ink-ripple,
    .md-checked:not([disabled]).md-primary .md-ink-ripple,
    &.md-default-theme.md-primary .md-checked:not([disabled]) .md-ink-ripple,
    &.md-primary .md-checked:not([disabled]) .md-ink-ripple {
        color: rgba(45, 50, 62, 0.26);
    }

    &.md-default-theme.md-focused:not(:empty) .md-checked.md-primary .md-container:before,
    &.md-focused:not(:empty) .md-checked.md-primary .md-container:before,
    &.md-default-theme.md-focused:not(:empty).md-primary .md-checked .md-container:before,
    &.md-focused:not(:empty).md-primary .md-checked .md-container:before {
        background-color: rgba(45, 50, 62, 0.26);
    }
}

md-input-container {
    &:not(.md-input-focused):not(.md-input-invalid) md-select {
        &.md-default-theme .md-select-value span:first-child:after,
        .md-select-value span:first-child:after {
            color: rgba(0, 0, 0, 0.38);
        }
    }

    &.md-input-focused:not(.md-input-has-value) md-select {
        &.md-default-theme .md-select-value,
        .md-select-value,
        &.md-default-theme .md-select-value.md-select-placeholder,
        .md-select-value.md-select-placeholder {
            color: rgb(45, 50, 62);
        }
    }

    &.md-input-invalid md-select {
        &.md-default-theme.md-no-underline .md-select-value,
        &.md-no-underline .md-select-value {
            border-bottom-color: transparent !important;
        }
    }
}

md-select {
    &.md-default-theme .md-select-value,
    .md-select-value {
        border-bottom-color: rgba(0, 0, 0, 0.12);
    }

    &.md-default-theme .md-select-value.md-select-placeholder,
    .md-select-value.md-select-placeholder {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-no-underline .md-select-value,
    &.md-no-underline .md-select-value,
    &.md-default-theme.ng-invalid.ng-touched.md-no-underline .md-select-value,
    &.ng-invalid.ng-touched.md-no-underline .md-select-value {
        border-bottom-color: transparent !important;
    }

    &.md-default-theme:not([disabled]):focus .md-select-value,
    &:not([disabled]):focus .md-select-value {
        border-bottom-color: rgb(45, 50, 62);
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme:not([disabled]):focus .md-select-value.md-select-placeholder,
    &:not([disabled]):focus .md-select-value.md-select-placeholder {
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme:not([disabled]):focus.md-no-underline .md-select-value,
    &:not([disabled]):focus.md-no-underline .md-select-value {
        border-bottom-color: transparent !important;
    }

    &.md-default-theme[disabled] .md-select-icon,
    &[disabled] .md-select-icon,
    &.md-default-theme[disabled] .md-select-value,
    &[disabled] .md-select-value,
    &.md-default-theme[disabled] .md-select-value.md-select-placeholder,
    &[disabled] .md-select-value.md-select-placeholder {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme .md-select-icon,
    .md-select-icon {
        color: rgba(0, 0, 0, 0.54);
    }
}

md-select-menu {
    &.md-default-theme md-content md-option[selected],
    md-content md-option[selected] {
        color: rgb(60, 66, 82);
    }

    &.md-default-theme md-content md-option[selected]:focus,
    md-content md-option[selected]:focus {
        color: rgb(53, 58, 72);
    }
}

.md-checkbox-enabled {
    &.md-default-theme .md-ripple,
    .md-ripple {
        color: rgb(53, 58, 72);
    }

    &.md-default-theme .md-ink-ripple,
    .md-ink-ripple {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme[selected] .md-ink-ripple,
    &[selected] .md-ink-ripple {
        color: rgba(45, 50, 62, 0.87);
    }

    &.md-default-theme:not(.md-checked) .md-icon,
    &:not(.md-checked) .md-icon {
        border-color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme[selected] .md-icon,
    &[selected] .md-icon {
        background-color: rgba(45, 50, 62, 0.87);
    }

    &.md-default-theme[selected].md-focused .md-container:before,
    &[selected].md-focused .md-container:before {
        background-color: rgba(45, 50, 62, 0.26);
    }

    &.md-default-theme[selected] .md-icon:after,
    &[selected] .md-icon:after {
        border-color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme .md-indeterminate[disabled] .md-container,
    .md-indeterminate[disabled] .md-container {
        color: rgba(0, 0, 0, 0.38);
    }
}

md-slider {
    &.md-default-theme.md-primary .md-focus-ring,
    &.md-primary .md-focus-ring {
        background-color: rgba(158, 161, 169, 0.38);
    }

    &.md-default-theme.md-primary .md-track.md-track-fill,
    &.md-primary .md-track.md-track-fill {
        background-color: rgb(45, 50, 62);
    }

    &.md-default-theme.md-primary .md-thumb:after,
    &.md-primary .md-thumb:after {
        border-color: rgb(45, 50, 62);
        background-color: rgb(45, 50, 62);
    }

    &.md-default-theme.md-primary .md-sign,
    &.md-primary .md-sign {
        background-color: rgb(45, 50, 62);
    }

    &.md-default-theme.md-primary .md-sign:after,
    &.md-primary .md-sign:after {
        border-top-color: rgb(45, 50, 62);
    }

    &.md-default-theme.md-primary[md-vertical] .md-sign:after,
    &.md-primary[md-vertical] .md-sign:after {
        border-top-color: transparent;
        border-left-color: rgb(45, 50, 62);
    }

    &.md-default-theme.md-primary .md-thumb-text,
    &.md-primary .md-thumb-text {
        color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme[disabled] .md-thumb:after,
    &[disabled] .md-thumb:after {
        border-color: transparent;
    }
}

md-slider-container[disabled] > {
    :first-child:not(md-slider), :last-child:not(md-slider) {
        color: rgba(0, 0, 0, 0.38);
    }
}

.md-subheader {
    &.md-default-theme.md-primary,
    &.md-primary {
        color: rgb(45, 50, 62);
    }
}

md-switch {
    &.md-default-theme.md-checked.md-primary .md-ink-ripple,
    &.md-checked.md-primary .md-ink-ripple {
        color: rgb(45, 50, 62);
    }

    &.md-default-theme.md-checked.md-primary .md-thumb,
    &.md-checked.md-primary .md-thumb {
        background-color: rgb(45, 50, 62);
    }

    &.md-default-theme.md-checked.md-primary .md-bar,
    &.md-checked.md-primary .md-bar {
        background-color: rgba(45, 50, 62, 0.5);
    }

    &.md-default-theme.md-checked.md-primary.md-focused .md-thumb:before,
    &.md-checked.md-primary.md-focused .md-thumb:before {
        background-color: rgba(45, 50, 62, 0.26);
    }
}

md-tabs {
    &.md-default-theme .md-paginator md-icon,
    .md-paginator md-icon {
        color: rgb(45, 50, 62);
    }

    &.md-default-theme .md-tab,
    .md-tab {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme .md-tab[disabled],
    .md-tab[disabled],
    &.md-default-theme .md-tab[disabled] md-icon,
    .md-tab[disabled] md-icon {
        color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme .md-tab.md-active,
    .md-tab.md-active,
    &.md-default-theme .md-tab.md-active md-icon,
    .md-tab.md-active md-icon,
    &.md-default-theme .md-tab.md-focused,
    .md-tab.md-focused,
    &.md-default-theme .md-tab.md-focused md-icon,
    .md-tab.md-focused md-icon {
        color: rgb(45, 50, 62);
    }

    &.md-default-theme .md-tab.md-focused,
    .md-tab.md-focused {
        background: rgba(45, 50, 62, 0.1);
    }

    &.md-default-theme.md-primary > md-tabs-wrapper,
    &.md-primary > md-tabs-wrapper {
        background-color: rgb(45, 50, 62);
    }

    &.md-default-theme.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]),
    &.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]),
    &.md-default-theme.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon,
    &.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
        color: rgb(197, 198, 203);
    }

    &.md-default-theme.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active,
    &.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active,
    &.md-default-theme.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon,
    &.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon,
    &.md-default-theme.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused,
    &.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused,
    &.md-default-theme.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon,
    &.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
        color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused,
    &.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
        background: rgba(255, 255, 255, 0.1);
    }
}

md-toolbar > md-tabs {
    &.md-default-theme {
        > md-tabs-wrapper {
            background-color: rgb(45, 50, 62);
        }

        > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
            color: rgb(197, 198, 203);
        }

        > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
            color: rgba(255, 255, 255, 0.87);
        }

        > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
            background: rgba(255, 255, 255, 0.1);
        }
    }

    > md-tabs-wrapper {
        background-color: rgb(45, 50, 62);
    }

    > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
        color: rgb(197, 198, 203);
    }

    > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
        color: rgba(255, 255, 255, 0.87);
    }

    > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
        background: rgba(255, 255, 255, 0.1);
    }
}

md-toast {
    &.md-default-theme .md-toast-content .md-button.md-highlight.md-primary,
    .md-toast-content .md-button.md-highlight.md-primary {
        color: rgb(45, 50, 62);
    }
}

md-toolbar {
    &.md-default-theme:not(.md-menu-toolbar),
    &:not(.md-menu-toolbar) {
        background-color: rgb(45, 50, 62);
        color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme:not(.md-menu-toolbar) md-icon,
    &:not(.md-menu-toolbar) md-icon {
        color: rgba(255, 255, 255, 0.87);
        fill: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme:not(.md-menu-toolbar) .md-button[disabled] md-icon,
    &:not(.md-menu-toolbar) .md-button[disabled] md-icon {
        color: rgba(255, 255, 255, 0.26);
        fill: rgba(255, 255, 255, 0.26);
    }
}