.md-panel._md-panel-backdrop {
    transition: none !important;
}

.mdpanel--loader.md-panel {
    transform: translateX(-50%) translateY(-50%) translateZ(0);
}

.md-panel-is-showing {
    .dialog--loader {
        opacity: 1;
    }
}

.dialog--loader {
    .md-title {
        text-align: center;
    }
}

[role="dialog"] {
    .md-button.md-primary.md-confirm-button {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        background-color: #2d323e;
        color: rgba(255, 255, 255, 0.87);

        &:not([disabled]):hover {
            background-color: #2d323e;
        }

        &:not([disabled]):active {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
        }
    }
}

.md-dialog-container {
    top: 64px !important;
    height: calc(100vh - 64px) !important;

    @media screen and (min-width: $layout-breakpoint-sm) {
        top: 112px !important;
        height: calc(100vh - 112px) !important;
    }
}

// Remove entrance/exit transitions and some other CSS render-heavy styles for dialogs
md-dialog {
    max-width: 100vw;
    max-height: 100vh;
    height: auto;
    top: 0;
    border-radius: 0;

    @media screen and (min-width: $layout-breakpoint-sm) {
        max-width: 95vw;
        max-height: calc(100vh - 150px);
    }

    &.dialog--loader {
        height: auto;
    }

    &.md-transition-in {
        transition: none;
    }

    &.md-transition-out {
        transition: none;
    }

    .form-content {
        max-height: calc(100vh - 214px);
        overflow: scroll;
    }
}

.container--dialog-form {
    width: 100%;

    @media screen and (min-width: $layout-breakpoint-sm) {
        width: 95vw;

        &.container--case-attorney-form {
            width: 100%;
        }
    }

    md-toolbar {
        min-height: 64px;
    }
}

.md-dialog-container {
    z-index: 99;

    &.draggable {
        height: auto !important;
        width: auto;
        left: 50%;
        transform: translateX(-50%) translateZ(0);

        md-dialog {
            margin: 10px 20px 25px;
        }

        .md-panel {
            touch-action: none;
        }
    }
}

.md-scroll-mask {
    z-index: 50;
}

md-backdrop.md-dialog-backdrop {
    z-index: 89;
}

.md-panel-outer-wrapper {
    height: calc(100vh - 64px) !important;
    top: 64px;
    left: 0;
    width: 100%;
    transform: none;

    @media screen and (min-width: $layout-breakpoint-sm) {
        height: auto !important;
        width: auto;
        top: 185px;
        left: 50%;
        transform: translateX(-50%) translateZ(0);

        [class*="state-"] & {
            top: 20vh;
        }
    }

    .container--dialog-form {
        opacity: 1;
    }

    .md-panel-inner-wrapper {
        position: relative;
    }

    .md-panel {
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        height: calc(100vh - 64px);

        @media screen and (min-width: $layout-breakpoint-sm) {
            height: auto;
        }
    }
}

md-dialog {
    .message-prompt-form {
        md-dialog-content {
            padding: 8px;
        }
    }
}

#minimized-panels {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0.5em 0.5em 0 0.5em;
    z-index: 1;
}

.panel-minimized {
    display: none;
}