.login--form {
    width: 384px;
    max-width: 100%;
    padding: 12px;
    background: #fff;
    text-align: center;
    margin: 5% auto 0;

    @media screen and (min-width: $layout-breakpoint-sm) {
        max-width: 384px;
        box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    .logo {
        width: 128px;
        height: 128px;
        line-height: 128px;
        font-size: 86px;
        font-weight: 500;
        margin: 12px auto;
        color: #FFF;
        border-radius: 2px;
    }

    .title {
        font-size: 17px;
        margin: 16px 0 32px;
    }

    label {
        text-align: left;
    }
}