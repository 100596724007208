md-autocomplete {
    &.md-default-theme.md-hue-2,
    &.md-hue-2 {
        background: rgb(255, 255, 255);
    }

    &.md-default-theme.md-hue-2[disabled]:not([md-floating-label]),
    &.md-hue-2[disabled]:not([md-floating-label]) {
        background: rgb(245, 245, 245);
    }

    &.md-default-theme.md-hue-2 button md-icon path,
    &.md-hue-2 button md-icon path {
        fill: rgb(117, 117, 117);
    }

    &.md-default-theme.md-hue-2 button:after,
    &.md-hue-2 button:after {
        background: rgba(117, 117, 117, 0.3);
    }
}

.md-autocomplete-suggestions-container {
    &.md-default-theme.md-hue-2,
    &.md-hue-2 {
        background: rgb(255, 255, 255);
    }

    &.md-default-theme.md-hue-2 .md-autocomplete-suggestion,
    &.md-hue-2 .md-autocomplete-suggestion {
        color: rgb(33, 33, 33);
    }

    &.md-default-theme.md-hue-2 .md-autocomplete-suggestion .highlight,
    &.md-hue-2 .md-autocomplete-suggestion .highlight {
        color: rgb(117, 117, 117);
    }

    &.md-default-theme.md-hue-2 .md-autocomplete-suggestion.selected,
    &.md-hue-2 .md-autocomplete-suggestion.selected,
    &.md-default-theme.md-hue-2 .md-autocomplete-suggestion:hover,
    &.md-hue-2 .md-autocomplete-suggestion:hover {
        background: rgb(238, 238, 238);
    }
}

md-backdrop {
    background-color: rgba(33, 33, 33, 0);

    &.md-opaque {
        &.md-default-theme.md-hue-2,
    &.md-hue-2 {
            background-color: rgba(33, 33, 33, 1);
        }
    }
}

md-bottom-sheet {
    &.md-default-theme.md-hue-2,
    &.md-hue-2 {
        background-color: rgb(250, 250, 250);
        border-top-color: rgb(224, 224, 224);
    }

    &.md-default-theme.md-hue-2 .md-subheader,
    &.md-hue-2 .md-subheader {
        background-color: rgb(250, 250, 250);
        color: rgba(0, 0, 0, 0.87);
    }
}

.md-button {
    &.md-default-theme.md-hue-2:not([disabled]).md-focused,
    &.md-hue-2:not([disabled]).md-focused,
    &.md-default-theme.md-hue-2:not([disabled]):hover,
    &.md-hue-2:not([disabled]):hover {
        background-color: rgba(158, 158, 158, 0.2);
    }

    &.md-default-theme.md-hue-2:not([disabled]).md-icon-button:hover,
    &.md-hue-2:not([disabled]).md-icon-button:hover {
        background-color: transparent;
    }

    &.md-default-theme.md-hue-2.md-raised,
    &.md-hue-2.md-raised {
        color: rgb(33, 33, 33);
        background-color: rgb(250, 250, 250);
    }

    &.md-default-theme.md-hue-2.md-raised:not([disabled]) md-icon,
    &.md-hue-2.md-raised:not([disabled]) md-icon {
        color: rgb(33, 33, 33);
    }

    &.md-default-theme.md-hue-2.md-raised:not([disabled]):hover,
    &.md-hue-2.md-raised:not([disabled]):hover {
        background-color: rgb(250, 250, 250);
    }

    &.md-default-theme.md-hue-2.md-raised:not([disabled]).md-focused,
    &.md-hue-2.md-raised:not([disabled]).md-focused {
        background-color: rgb(238, 238, 238);
    }

    &.md-default-theme.md-hue-2.md-fab[disabled],
    &.md-hue-2.md-fab[disabled],
    &.md-default-theme.md-hue-2.md-raised[disabled],
    &.md-hue-2.md-raised[disabled] {
        background-color: rgba(0, 0, 0, 0.12);
    }

    &.md-default-theme.md-hue-2[disabled],
    &.md-hue-2[disabled] {
        background-color: transparent;
    }
}

md-card {
    &.md-default-theme.md-hue-2,
    &.md-hue-2 {
        color: rgba(0, 0, 0, 0.87);
        background-color: rgb(255, 255, 255);
        border-radius: 2px;
    }

    &.md-default-theme.md-hue-2 md-card-header md-card-avatar md-icon,
    &.md-hue-2 md-card-header md-card-avatar md-icon {
        color: rgb(245, 245, 245);
        background-color: rgba(0, 0, 0, 0.38);
    }
}

md-checkbox {
    &.md-default-theme.md-hue-2.md-checked .md-ripple,
    &.md-hue-2.md-checked .md-ripple {
        color: rgb(117, 117, 117);
    }

    &.md-default-theme.md-hue-2[disabled].md-checked .md-icon,
    &.md-hue-2[disabled].md-checked .md-icon {
        background-color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-hue-2[disabled].md-checked .md-icon:after,
    &.md-hue-2[disabled].md-checked .md-icon:after {
        border-color: rgb(238, 238, 238);
    }
}

md-chips {
    &.md-default-theme.md-hue-2 md-chip,
    &.md-hue-2 md-chip {
        background: rgb(224, 224, 224);
        color: rgb(66, 66, 66);
    }

    &.md-default-theme.md-hue-2 md-chip md-icon,
    &.md-hue-2 md-chip md-icon {
        color: rgb(97, 97, 97);
    }

    &.md-default-theme.md-hue-2 md-chip._md-chip-editing,
    &.md-hue-2 md-chip._md-chip-editing {
        background: transparent;
        color: rgb(66, 66, 66);
    }

    &.md-default-theme.md-hue-2 md-chip-remove .md-button md-icon path,
    &.md-hue-2 md-chip-remove .md-button md-icon path {
        fill: rgb(158, 158, 158);
    }
}

.md-contact-suggestion span.md-contact-email {
    color: rgb(189, 189, 189);
}

md-content {
    &.md-default-theme.md-hue-2,
    &.md-hue-2 {
        color: rgba(0, 0, 0, 0.87);
        background-color: rgb(255, 255, 255);
    }
}

.md-calendar {
    &.md-default-theme.md-hue-2,
    &.md-hue-2 {
        background: rgb(255, 255, 255);
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme.md-hue-2 tr:last-child td,
    &.md-hue-2 tr:last-child td {
        border-bottom-color: rgb(238, 238, 238);
    }
}

.md-default-theme.md-hue-2 .md-calendar-day-header,
    .md-hue-2 .md-calendar-day-header {
    background: rgb(224, 224, 224);
    color: rgba(0, 0, 0, 0.87);
}

.md-calendar-date.md-focus {
    .md-default-theme.md-hue-2 .md-calendar-date-selection-indicator,
    .md-hue-2 .md-calendar-date-selection-indicator {
        background: rgb(224, 224, 224);
    }
}

.md-default-theme.md-hue-2 .md-calendar-date-selection-indicator:hover,
    .md-hue-2 .md-calendar-date-selection-indicator:hover {
    background: rgb(224, 224, 224);
}

.md-default-theme.md-hue-2 .md-calendar-date-disabled,
    .md-hue-2 .md-calendar-date-disabled,
    .md-default-theme.md-hue-2 .md-calendar-month-label-disabled,
    .md-hue-2 .md-calendar-month-label-disabled {
    color: rgba(0, 0, 0, 0.435);
}

.md-default-theme.md-hue-2 .md-datepicker-calendar-pane,
    .md-hue-2 .md-datepicker-calendar-pane {
    border-color: rgb(255, 255, 255);
}

.md-default-theme.md-hue-2 .md-datepicker-calendar,
    .md-hue-2 .md-datepicker-calendar {
    background: rgb(255, 255, 255);
}

.md-default-theme.md-hue-2 .md-datepicker-input-mask-opaque,
    .md-hue-2 .md-datepicker-input-mask-opaque {
    box-shadow: 0 0 0 9999px rgb(255, 255, 255);
}

.md-default-theme.md-hue-2 .md-datepicker-open .md-datepicker-input-container,
    .md-hue-2 .md-datepicker-open .md-datepicker-input-container {
    background: rgb(255, 255, 255);
}

md-dialog {
    &.md-default-theme.md-hue-2,
    &.md-hue-2 {
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        color: rgba(0, 0, 0, 0.87);
    }
}

[disabled] md-input-container {
    &.md-default-theme.md-hue-2 .md-input,
    &.md-hue-2 .md-input {
        border-bottom-color: transparent;
        color: rgba(0, 0, 0, 0.38);
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.38) 0, rgba(0, 0, 0, 0.38) 33%, transparent 0);
        background-image: -ms-linear-gradient(left, transparent 0, rgba(0, 0, 0, 0.38) 100%);
    }
}

md-input-container {
    &.md-default-theme.md-hue-2 .md-input[disabled],
    &.md-hue-2 .md-input[disabled] {
        border-bottom-color: transparent;
        color: rgba(0, 0, 0, 0.38);
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.38) 0, rgba(0, 0, 0, 0.38) 33%, transparent 0);
        background-image: -ms-linear-gradient(left, transparent 0, rgba(0, 0, 0, 0.38) 100%);
    }
}

md-list {
    &.md-default-theme.md-hue-2 .md-proxy-focus.md-focused div.md-no-style,
    &.md-hue-2 .md-proxy-focus.md-focused div.md-no-style {
        background-color: rgb(245, 245, 245);
    }

    &.md-default-theme.md-hue-2 md-list-item .md-avatar-icon,
    &.md-hue-2 md-list-item .md-avatar-icon {
        background-color: rgba(0, 0, 0, 0.38);
        color: rgb(245, 245, 245);
    }
}

md-menu-content {
    &.md-default-theme.md-hue-2,
    &.md-hue-2 {
        background-color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-hue-2 md-menu-item,
    &.md-hue-2 md-menu-item {
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme.md-hue-2 md-menu-item md-icon,
    &.md-hue-2 md-menu-item md-icon {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-2 md-menu-item .md-button[disabled],
    &.md-hue-2 md-menu-item .md-button[disabled],
    &.md-default-theme.md-hue-2 md-menu-item .md-button[disabled] md-icon,
    &.md-hue-2 md-menu-item .md-button[disabled] md-icon {
        color: rgba(0, 0, 0, 0.25);
    }

    &.md-default-theme.md-hue-2 md-menu-divider,
    &.md-hue-2 md-menu-divider {
        background-color: rgba(0, 0, 0, 0.11);
    }
}

md-menu-bar {
    &.md-default-theme.md-hue-2 md-menu.md-open > button,
    &.md-hue-2 md-menu.md-open > button,
    &.md-default-theme.md-hue-2 md-menu > button:focus,
    &.md-hue-2 md-menu > button:focus {
        outline: none;
        background: rgb(238, 238, 238);
    }

    &.md-default-theme.md-hue-2.md-open:not(.md-keyboard-mode) md-menu:hover > button,
    &.md-hue-2.md-open:not(.md-keyboard-mode) md-menu:hover > button {
        background-color: rgba(158, 158, 158, 0.2);
    }

    &.md-default-theme.md-hue-2:not(.md-keyboard-mode):not(.md-open) md-menu button:focus,
    &.md-hue-2:not(.md-keyboard-mode):not(.md-open) md-menu button:focus,
    &.md-default-theme.md-hue-2:not(.md-keyboard-mode):not(.md-open) md-menu button:hover,
    &.md-hue-2:not(.md-keyboard-mode):not(.md-open) md-menu button:hover {
        background: transparent;
    }
}

md-menu-content {
    &.md-default-theme.md-hue-2 .md-menu > .md-button:after,
    &.md-hue-2 .md-menu > .md-button:after {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-2 .md-menu.md-open > .md-button,
    &.md-hue-2 .md-menu.md-open > .md-button {
        background-color: rgba(158, 158, 158, 0.2);
    }
}

md-toolbar {
    &.md-default-theme.md-hue-2.md-menu-toolbar,
    &.md-hue-2.md-menu-toolbar {
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
    }

    &.md-default-theme.md-hue-2.md-menu-toolbar md-toolbar-filler md-icon,
    &.md-hue-2.md-menu-toolbar md-toolbar-filler md-icon {
        color: rgba(255, 255, 255, 0.87);
    }
}

md-nav-bar {
    &.md-default-theme.md-hue-2 .md-nav-bar,
    &.md-hue-2 .md-nav-bar {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.12);
    }
}

._md-panel-backdrop {
    &.md-default-theme.md-hue-2,
    &.md-hue-2 {
        background-color: rgba(33, 33, 33, 1);
    }
}

md-select {
    &.md-default-theme.md-hue-2[disabled] .md-select-value,
    &.md-hue-2[disabled] .md-select-value {
        border-bottom-color: transparent;
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.38) 0, rgba(0, 0, 0, 0.38) 33%, transparent 0);
        background-image: -ms-linear-gradient(left, transparent 0, rgba(0, 0, 0, 0.38) 100%);
    }
}

md-select-menu {
    &.md-default-theme.md-hue-2 md-content,
    &.md-hue-2 md-content {
        background: rgb(255, 255, 255);
    }

    &.md-default-theme.md-hue-2 md-content md-optgroup,
    &.md-hue-2 md-content md-optgroup {
        color: rgba(117, 117, 117, 0.87);
    }

    &.md-default-theme.md-hue-2 md-content md-option,
    &.md-hue-2 md-content md-option {
        color: rgba(33, 33, 33, 0.87);
    }

    &.md-default-theme.md-hue-2 md-content md-option[disabled] .md-text,
    &.md-hue-2 md-content md-option[disabled] .md-text {
        color: rgba(189, 189, 189, 0.87);
    }

    &.md-default-theme.md-hue-2 md-content md-option:not([disabled]):focus,
    &.md-hue-2 md-content md-option:not([disabled]):focus,
    &.md-default-theme.md-hue-2 md-content md-option:not([disabled]):hover,
    &.md-hue-2 md-content md-option:not([disabled]):hover {
        background: rgb(238, 238, 238);
    }
}

.md-checkbox-enabled {
    &.md-default-theme.md-hue-2[selected] .md-ripple,
    &.md-hue-2[selected] .md-ripple {
        color: rgb(117, 117, 117);
    }

    &.md-default-theme.md-hue-2 md-option .md-text,
    &.md-hue-2 md-option .md-text {
        color: rgba(33, 33, 33, 0.87);
    }
}

md-sidenav {
    &.md-default-theme.md-hue-2,
    &.md-hue-2,
    &.md-default-theme.md-hue-2 md-content,
    &.md-hue-2 md-content {
        background-color: rgb(255, 255, 255);
    }
}

md-slider {
    &.md-default-theme.md-hue-2 .md-track,
    &.md-hue-2 .md-track {
        background-color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-hue-2 .md-track-ticks,
    &.md-hue-2 .md-track-ticks {
        color: rgba(0, 0, 0, 0.87);
    }

    &.md-default-theme.md-hue-2 .md-disabled-thumb,
    &.md-hue-2 .md-disabled-thumb {
        border-color: rgb(245, 245, 245);
        background-color: rgb(245, 245, 245);
    }

    &.md-default-theme.md-hue-2.md-min .md-thumb:after,
    &.md-hue-2.md-min .md-thumb:after {
        background-color: rgb(245, 245, 245);
        border-color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-hue-2.md-min .md-focus-ring,
    &.md-hue-2.md-min .md-focus-ring {
        background-color: rgba(0, 0, 0, 0.38);
    }

    &.md-default-theme.md-hue-2.md-min[md-discrete] .md-thumb:after,
    &.md-hue-2.md-min[md-discrete] .md-thumb:after {
        background-color: rgba(0, 0, 0, 0.87);
        border-color: transparent;
    }

    &.md-default-theme.md-hue-2.md-min[md-discrete] .md-sign,
    &.md-hue-2.md-min[md-discrete] .md-sign {
        background-color: rgb(189, 189, 189);
    }

    &.md-default-theme.md-hue-2.md-min[md-discrete] .md-sign:after,
    &.md-hue-2.md-min[md-discrete] .md-sign:after {
        border-top-color: rgb(189, 189, 189);
    }

    &.md-default-theme.md-hue-2.md-min[md-discrete][md-vertical] .md-sign:after,
    &.md-hue-2.md-min[md-discrete][md-vertical] .md-sign:after {
        border-top-color: transparent;
        border-left-color: rgb(189, 189, 189);
    }

    &.md-default-theme.md-hue-2[disabled]:not(.md-min) .md-thumb:after,
    &.md-hue-2[disabled]:not(.md-min) .md-thumb:after,
    &.md-default-theme.md-hue-2[disabled][md-discrete] .md-thumb:after,
    &.md-hue-2[disabled][md-discrete] .md-thumb:after {
        background-color: rgba(0, 0, 0, 0.38);
        border-color: transparent;
    }

    &.md-default-theme.md-hue-2[disabled][readonly] .md-sign,
    &.md-hue-2[disabled][readonly] .md-sign {
        background-color: rgb(189, 189, 189);
    }

    &.md-default-theme.md-hue-2[disabled][readonly] .md-sign:after,
    &.md-hue-2[disabled][readonly] .md-sign:after {
        border-top-color: rgb(189, 189, 189);
    }

    &.md-default-theme.md-hue-2[disabled][readonly][md-vertical] .md-sign:after,
    &.md-hue-2[disabled][readonly][md-vertical] .md-sign:after {
        border-top-color: transparent;
        border-left-color: rgb(189, 189, 189);
    }

    &.md-default-theme.md-hue-2[disabled][readonly] .md-disabled-thumb,
    &.md-hue-2[disabled][readonly] .md-disabled-thumb {
        border-color: transparent;
        background-color: transparent;
    }
}

.md-subheader {
    &.md-default-theme.md-hue-2,
    &.md-hue-2 {
        color: rgba(0, 0, 0, 0.54);
        background-color: rgb(255, 255, 255);
    }
}

md-switch {
    &.md-default-theme.md-hue-2 .md-ink-ripple,
    &.md-hue-2 .md-ink-ripple {
        color: rgb(158, 158, 158);
    }

    &.md-default-theme.md-hue-2 .md-thumb,
    &.md-hue-2 .md-thumb {
        background-color: rgb(250, 250, 250);
    }

    &.md-default-theme.md-hue-2 .md-bar,
    &.md-hue-2 .md-bar {
        background-color: rgb(158, 158, 158);
    }

    &.md-default-theme.md-hue-2[disabled] .md-thumb,
    &.md-hue-2[disabled] .md-thumb {
        background-color: rgb(189, 189, 189);
    }

    &.md-default-theme.md-hue-2[disabled] .md-bar,
    &.md-hue-2[disabled] .md-bar {
        background-color: rgba(0, 0, 0, 0.12);
    }
}

md-tabs {
    &.md-default-theme.md-hue-2 md-tabs-wrapper,
    &.md-hue-2 md-tabs-wrapper {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.12);
    }
}

md-toast {
    &.md-default-theme.md-hue-2 .md-toast-content,
    &.md-hue-2 .md-toast-content {
        background-color: #323232;
        color: rgb(250, 250, 250);
    }

    &.md-default-theme.md-hue-2 .md-toast-content .md-button,
    &.md-hue-2 .md-toast-content .md-button {
        color: rgb(250, 250, 250);
    }
}

.md-panel.md-tooltip {
    &.md-default-theme.md-hue-2,
    &.md-hue-2 {
        color: rgba(255, 255, 255, 0.87);
        background-color: rgb(97, 97, 97);
    }
}

body {
    &.md-default-theme.md-hue-2,
    &.md-hue-2 {
        color: rgba(0, 0, 0, 0.87);
        background-color: rgb(245, 245, 245);
    }
}

html {
    &.md-default-theme.md-hue-2,
    &.md-hue-2 {
        color: rgba(0, 0, 0, 0.87);
        background-color: rgb(245, 245, 245);
    }
}