.password-strength-meter {
    background: #fafafa;
    padding: 5px;

    &:not(.visible) {
        display: none;
    }

    &.score-level-1 {
        background: #ff9999;
    }

    &.score-level-2 {
        background: #ffc999;
    }

    &.score-level-3 {
        background: #fffb88;
    }

    &.score-level-4 {
        background: #5dd662;
    }
}

.password-validation-rules {
    li {
        font-weight: 600;
        color: #e00000;

        &.valid {
            color: #157d19;
        }
    }
}