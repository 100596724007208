.md-button {
    &.md-default-theme.md-fab md-icon, &.md-fab md-icon {
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-fab, &.md-fab {
        background-color: rgb(3, 155, 229);
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-fab:not([disabled]) .md-icon, &.md-fab:not([disabled]) .md-icon {
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-fab:not([disabled]).md-focused, &.md-fab:not([disabled]).md-focused, &.md-default-theme.md-fab:not([disabled]):hover, &.md-fab:not([disabled]):hover {
        background-color: rgb(0, 145, 234);
    }

    &.md-default-theme.md-accent, &.md-accent {
        color: rgb(3, 155, 229);
    }

    &.md-default-theme.md-accent.md-fab, &.md-accent.md-fab, &.md-default-theme.md-accent.md-raised, &.md-accent.md-raised {
        color: rgb(255, 255, 255);
        background-color: rgb(3, 155, 229);
    }

    &.md-default-theme.md-accent.md-fab:not([disabled]) md-icon, &.md-accent.md-fab:not([disabled]) md-icon, &.md-default-theme.md-accent.md-raised:not([disabled]) md-icon, &.md-accent.md-raised:not([disabled]) md-icon {
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-accent.md-fab:not([disabled]).md-focused, &.md-accent.md-fab:not([disabled]).md-focused, &.md-default-theme.md-accent.md-fab:not([disabled]):hover, &.md-accent.md-fab:not([disabled]):hover, &.md-default-theme.md-accent.md-raised:not([disabled]).md-focused, &.md-accent.md-raised:not([disabled]).md-focused, &.md-default-theme.md-accent.md-raised:not([disabled]):hover, &.md-accent.md-raised:not([disabled]):hover {
        background-color: rgb(0, 145, 234);
    }

    &.md-default-theme.md-accent:not([disabled]) md-icon, &.md-accent:not([disabled]) md-icon {
        color: rgb(3, 155, 229);
    }

    &.md-default-theme.md-accent[disabled], &.md-accent[disabled], &.md-default-theme.md-fab[disabled], &.md-fab[disabled], &.md-default-theme.md-raised[disabled], &.md-raised[disabled], &.md-default-theme.md-warn[disabled], &.md-warn[disabled], &.md-default-theme[disabled], &[disabled] {
        color: rgba(0, 0, 0, 0.38);
        cursor: default;
    }

    &.md-default-theme.md-accent[disabled] md-icon, &.md-accent[disabled] md-icon, &.md-default-theme.md-fab[disabled] md-icon, &.md-fab[disabled] md-icon, &.md-default-theme.md-raised[disabled] md-icon, &.md-raised[disabled] md-icon, &.md-default-theme.md-warn[disabled] md-icon, &.md-warn[disabled] md-icon, &.md-default-theme[disabled] md-icon, &[disabled] md-icon {
        color: rgba(0, 0, 0, 0.38);
    }
}

._md a {
    &.md-default-theme:not(.md-button).md-accent, &:not(.md-button).md-accent {
        color: rgb(3, 155, 229);
    }

    &.md-default-theme:not(.md-button).md-accent:hover, &:not(.md-button).md-accent:hover {
        color: rgb(0, 145, 234);
    }
}

md-checkbox {
    &.md-default-theme .md-ripple, .md-ripple {
        color: rgb(0, 145, 234);
    }

    &.md-default-theme.md-checked.md-focused .md-container:before, &.md-checked.md-focused .md-container:before {
        background-color: rgba(3, 155, 229, 0.26);
    }

    &.md-default-theme.md-checked .md-ink-ripple, &.md-checked .md-ink-ripple {
        color: rgba(3, 155, 229, 0.87);
    }

    &.md-default-theme.md-checked .md-icon, &.md-checked .md-icon {
        background-color: rgba(3, 155, 229, 0.87);
    }

    &.md-default-theme.md-checked .md-icon:after, &.md-checked .md-icon:after {
        border-color: rgba(255, 255, 255, 0.87);
    }
}

.md-accent {
    .md-default-theme .md-datepicker-input-container.md-datepicker-focused, .md-datepicker-input-container.md-datepicker-focused {
        border-bottom-color: rgb(3, 155, 229);
    }

    .md-default-theme .md-datepicker-open .md-datepicker-calendar-icon, .md-datepicker-open .md-datepicker-calendar-icon {
        color: rgb(3, 155, 229);
    }
}

.md-default-theme .md-datepicker-open.md-accent .md-datepicker-calendar-icon, .md-datepicker-open.md-accent .md-datepicker-calendar-icon {
    color: rgb(3, 155, 229);
}

md-icon {
    &.md-default-theme.md-accent, &.md-accent {
        color: rgb(3, 155, 229);
    }
}

md-input-container {
    &.md-default-theme:not(.md-input-invalid).md-input-focused.md-accent .md-input, &:not(.md-input-invalid).md-input-focused.md-accent .md-input {
        border-color: rgb(3, 155, 229);
    }

    &.md-default-theme:not(.md-input-invalid).md-input-focused.md-accent label, &:not(.md-input-invalid).md-input-focused.md-accent label, &.md-default-theme:not(.md-input-invalid).md-input-focused.md-accent md-icon, &:not(.md-input-invalid).md-input-focused.md-accent md-icon {
        color: rgb(3, 155, 229);
    }
}

md-list {
    &.md-default-theme md-list-item > md-icon.md-highlight.md-accent, md-list-item > md-icon.md-highlight.md-accent {
        color: rgb(3, 155, 229);
    }
}

md-nav-bar {
    &.md-default-theme md-nav-ink-bar, md-nav-ink-bar {
        color: rgb(3, 155, 229);
        background: rgb(3, 155, 229);
    }

    &.md-default-theme.md-accent > .md-nav-bar, &.md-accent > .md-nav-bar {
        background-color: rgb(3, 155, 229);
    }

    &.md-default-theme.md-accent > .md-nav-bar .md-button._md-nav-button, &.md-accent > .md-nav-bar .md-button._md-nav-button {
        color: rgb(128, 216, 255);
    }

    &.md-default-theme.md-accent > .md-nav-bar .md-button._md-nav-button.md-active, &.md-accent > .md-nav-bar .md-button._md-nav-button.md-active, &.md-default-theme.md-accent > .md-nav-bar .md-button._md-nav-button.md-focused, &.md-accent > .md-nav-bar .md-button._md-nav-button.md-focused {
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-accent > .md-nav-bar .md-button._md-nav-button.md-focused, &.md-accent > .md-nav-bar .md-button._md-nav-button.md-focused {
        background: rgba(255, 255, 255, 0.1);
    }

    &.md-default-theme.md-accent > .md-nav-bar md-nav-ink-bar, &.md-accent > .md-nav-bar md-nav-ink-bar {
        color: rgba(53, 58, 72, 1);
        background: rgba(53, 58, 72, 1);
    }
}

md-toolbar.md-accent > md-nav-bar {
    &.md-default-theme {
        > .md-nav-bar {
            background-color: rgb(3, 155, 229);
        }

        > .md-nav-bar .md-button._md-nav-button {
            color: rgb(128, 216, 255);
        }

        > .md-nav-bar .md-button._md-nav-button.md-active, > .md-nav-bar .md-button._md-nav-button.md-focused {
            color: rgb(255, 255, 255);
        }

        > .md-nav-bar .md-button._md-nav-button.md-focused {
            background: rgba(255, 255, 255, 0.1);
        }

        > .md-nav-bar md-nav-ink-bar {
            color: rgba(53, 58, 72, 1);
            background: rgba(53, 58, 72, 1);
        }
    }

    > .md-nav-bar {
        background-color: rgb(3, 155, 229);
    }

    > .md-nav-bar .md-button._md-nav-button {
        color: rgb(128, 216, 255);
    }

    > .md-nav-bar .md-button._md-nav-button.md-active, > .md-nav-bar .md-button._md-nav-button.md-focused {
        color: rgb(255, 255, 255);
    }

    > .md-nav-bar .md-button._md-nav-button.md-focused {
        background: rgba(255, 255, 255, 0.1);
    }

    > .md-nav-bar md-nav-ink-bar {
        color: rgba(53, 58, 72, 1);
        background: rgba(53, 58, 72, 1);
    }
}

md-progress-circular {
    &.md-default-theme.md-accent path, &.md-accent path {
        stroke: rgb(3, 155, 229);
    }
}

md-progress-linear {
    &.md-default-theme.md-accent .md-container, &.md-accent .md-container {
        background-color: rgb(179, 229, 252);
    }

    &.md-default-theme.md-accent .md-bar, &.md-accent .md-bar {
        background-color: rgb(3, 155, 229);
    }

    &.md-default-theme[md-mode=buffer].md-accent .md-bar1, &[md-mode=buffer].md-accent .md-bar1 {
        background-color: rgb(179, 229, 252);
    }

    &.md-default-theme[md-mode=buffer].md-accent .md-dashed:before, &[md-mode=buffer].md-accent .md-dashed:before {
        background: radial-gradient(rgb(179, 229, 252) 0, rgb(179, 229, 252) 16%, transparent 42%);
    }
}

md-radio-button {
    &.md-default-theme .md-on, .md-on {
        background-color: rgba(3, 155, 229, 0.87);
    }

    &.md-default-theme.md-checked .md-off, &.md-checked .md-off {
        border-color: rgba(3, 155, 229, 0.87);
    }

    &.md-default-theme.md-checked .md-ink-ripple, &.md-checked .md-ink-ripple {
        color: rgba(3, 155, 229, 0.87);
    }

    &.md-default-theme .md-container .md-ripple, .md-container .md-ripple {
        color: rgb(0, 145, 234);
    }
}

md-radio-group {
    &.md-default-theme .md-checked .md-ink-ripple, .md-checked .md-ink-ripple {
        color: rgba(3, 155, 229, 0.26);
    }

    &.md-default-theme.md-focused:not(:empty) .md-checked .md-container:before, &.md-focused:not(:empty) .md-checked .md-container:before {
        background-color: rgba(3, 155, 229, 0.26);
    }
}

md-select {
    &.md-default-theme:not([disabled]):focus.md-accent .md-select-value, &:not([disabled]):focus.md-accent .md-select-value {
        border-bottom-color: rgb(3, 155, 229);
    }
}

md-select-menu {
    &.md-default-theme md-content md-option[selected].md-accent, md-content md-option[selected].md-accent {
        color: rgb(3, 155, 229);
    }

    &.md-default-theme md-content md-option[selected].md-accent:focus, md-content md-option[selected].md-accent:focus {
        color: rgb(0, 145, 234);
    }
}

md-slider {
    &.md-default-theme .md-focus-ring, .md-focus-ring {
        background-color: rgba(64, 196, 255, 0.2);
    }

    &.md-default-theme .md-track.md-track-fill, .md-track.md-track-fill {
        background-color: rgb(3, 155, 229);
    }

    &.md-default-theme .md-thumb:after, .md-thumb:after {
        border-color: rgb(3, 155, 229);
        background-color: rgb(3, 155, 229);
    }

    &.md-default-theme .md-sign, .md-sign {
        background-color: rgb(3, 155, 229);
    }

    &.md-default-theme .md-sign:after, .md-sign:after {
        border-top-color: rgb(3, 155, 229);
    }

    &.md-default-theme[md-vertical] .md-sign:after, &[md-vertical] .md-sign:after {
        border-top-color: transparent;
        border-left-color: rgb(3, 155, 229);
    }

    &.md-default-theme .md-thumb-text, .md-thumb-text {
        color: rgb(255, 255, 255);
    }
}

.md-subheader {
    &.md-default-theme.md-accent, &.md-accent {
        color: rgb(3, 155, 229);
    }
}

md-switch {
    &.md-default-theme.md-checked .md-ink-ripple, &.md-checked .md-ink-ripple {
        color: rgb(3, 155, 229);
    }

    &.md-default-theme.md-checked .md-thumb, &.md-checked .md-thumb {
        background-color: rgb(3, 155, 229);
    }

    &.md-default-theme.md-checked .md-bar, &.md-checked .md-bar {
        background-color: rgba(3, 155, 229, 0.5);
    }

    &.md-default-theme.md-checked.md-focused .md-thumb:before, &.md-checked.md-focused .md-thumb:before {
        background-color: rgba(3, 155, 229, 0.26);
    }
}

md-tabs {
    &.md-default-theme md-ink-bar, md-ink-bar {
        color: rgb(3, 155, 229);
        background: rgb(3, 155, 229);
    }

    &.md-default-theme .md-tab .md-ripple-container, .md-tab .md-ripple-container {
        color: rgb(128, 216, 255);
    }

    &.md-default-theme.md-accent > md-tabs-wrapper, &.md-accent > md-tabs-wrapper {
        background-color: rgb(3, 155, 229);
    }

    &.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), &.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), &.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon, &.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
        color: rgb(128, 216, 255);
    }

    &.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, &.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, &.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, &.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, &.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon, &.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
        color: rgb(255, 255, 255);
    }

    &.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
        background: rgba(255, 255, 255, 0.1);
    }

    &.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-ink-bar, &.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-ink-bar {
        color: rgba(53, 58, 72, 1);
        background: rgba(53, 58, 72, 1);
    }
}

md-toolbar.md-accent > md-tabs {
    &.md-default-theme {
        > md-tabs-wrapper {
            background-color: rgb(3, 155, 229);
        }

        > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
            color: rgb(128, 216, 255);
        }

        > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
            color: rgb(255, 255, 255);
        }

        > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
            background: rgba(255, 255, 255, 0.1);
        }

        > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-ink-bar {
            color: rgba(53, 58, 72, 1);
            background: rgba(53, 58, 72, 1);
        }
    }

    > md-tabs-wrapper {
        background-color: rgb(3, 155, 229);
    }

    > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
        color: rgb(128, 216, 255);
    }

    > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
        color: rgb(255, 255, 255);
    }

    > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
        background: rgba(255, 255, 255, 0.1);
    }

    > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-ink-bar {
        color: rgba(53, 58, 72, 1);
        background: rgba(53, 58, 72, 1);
    }
}

md-toast {
    &.md-default-theme .md-toast-content .md-button.md-highlight, .md-toast-content .md-button.md-highlight {
        color: rgb(3, 155, 229);
    }
}

md-toolbar {
    &.md-default-theme:not(.md-menu-toolbar).md-accent, &:not(.md-menu-toolbar).md-accent {
        background-color: rgb(3, 155, 229);
        color: rgb(255, 255, 255);
    }

    &.md-default-theme:not(.md-menu-toolbar).md-accent .md-ink-ripple, &:not(.md-menu-toolbar).md-accent .md-ink-ripple {
        color: rgb(255, 255, 255);
    }

    &.md-default-theme:not(.md-menu-toolbar).md-accent md-icon, &:not(.md-menu-toolbar).md-accent md-icon {
        color: rgb(255, 255, 255);
        fill: rgb(255, 255, 255);
    }

    &.md-default-theme:not(.md-menu-toolbar).md-accent .md-button[disabled] md-icon, &:not(.md-menu-toolbar).md-accent .md-button[disabled] md-icon {
        color: rgba(255, 255, 255, 0.26);
        fill: rgba(255, 255, 255, 0.26);
    }
}