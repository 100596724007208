.is-logged-in {
    #toolbar {
        z-index: 9999999999 !important;
    }
}

#toolbar-navigation-container {
    position: relative;
    z-index: 100;

    @media print {
        display: none;
    }
}

#toolbar {
    background: $color-blue;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#104e73', endColorstr='#1e80bb', GradientType=1);

    form[name="vm.jumpToRequestForm"] {
        .md-errors-spacer {
            display: none;
        }
    }

    #printer-menu {
        .icon-printer {
            position: relative;
            top: 5px;
        }
    }
}

.toolbar--thin {
    padding: 0;
    min-height: 50px;

    .md-toolbar-tools {
        height: 50px;
    }
}

.toolbar .input--table-search {
    border: 1px solid #ddd;
    padding: 10px 15px;
    border-radius: 2px;
    margin: 6px 0;
    transition: all 250ms linear;
    background-color: #efefef;

    &:hover {
        background-color: #fafafa;
    }

    &:focus,
    &.ng-not-empty {
        background-color: #fff;
    }

    &:focus {
        border-color: #2d323e;
    }
}