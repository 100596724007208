.md-button {
    &.md-default-theme.md-hue-2.md-warn, &.md-hue-2.md-warn {
        color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2.md-warn.md-fab, &.md-hue-2.md-warn.md-fab, &.md-default-theme.md-hue-2.md-warn.md-raised, &.md-hue-2.md-warn.md-raised {
        color: rgba(255, 255, 255, 0.87);
        background-color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2.md-warn.md-fab:not([disabled]) md-icon, &.md-hue-2.md-warn.md-fab:not([disabled]) md-icon, &.md-default-theme.md-hue-2.md-warn.md-raised:not([disabled]) md-icon, &.md-hue-2.md-warn.md-raised:not([disabled]) md-icon {
        color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme.md-hue-2.md-warn.md-fab:not([disabled]).md-focused, &.md-hue-2.md-warn.md-fab:not([disabled]).md-focused, &.md-default-theme.md-hue-2.md-warn.md-fab:not([disabled]):hover, &.md-hue-2.md-warn.md-fab:not([disabled]):hover, &.md-default-theme.md-hue-2.md-warn.md-raised:not([disabled]).md-focused, &.md-hue-2.md-warn.md-raised:not([disabled]).md-focused, &.md-default-theme.md-hue-2.md-warn.md-raised:not([disabled]):hover, &.md-hue-2.md-warn.md-raised:not([disabled]):hover {
        background-color: rgb(229, 57, 53);
    }

    &.md-default-theme.md-hue-2.md-warn:not([disabled]) md-icon, &.md-hue-2.md-warn:not([disabled]) md-icon {
        color: rgb(198, 40, 40);
    }
}

._md a {
    &.md-default-theme.md-hue-2:not(.md-button).md-warn, &.md-hue-2:not(.md-button).md-warn {
        color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2:not(.md-button).md-warn:hover, &.md-hue-2:not(.md-button).md-warn:hover {
        color: rgb(211, 47, 47);
    }
}

md-checkbox {
    &.md-default-theme.md-hue-2:not([disabled]).md-warn .md-ripple, &.md-hue-2:not([disabled]).md-warn .md-ripple {
        color: rgb(229, 57, 53);
    }

    &.md-default-theme.md-hue-2:not([disabled]).md-warn .md-ink-ripple, &.md-hue-2:not([disabled]).md-warn .md-ink-ripple {
        color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-2:not([disabled]).md-warn.md-checked .md-ink-ripple, &.md-hue-2:not([disabled]).md-warn.md-checked .md-ink-ripple {
        color: rgba(198, 40, 40, 0.87);
    }

    &.md-default-theme.md-hue-2:not([disabled]).md-warn:not(.md-checked) .md-icon, &.md-hue-2:not([disabled]).md-warn:not(.md-checked) .md-icon {
        border-color: rgba(0, 0, 0, 0.54);
    }

    &.md-default-theme.md-hue-2:not([disabled]).md-warn.md-checked .md-icon, &.md-hue-2:not([disabled]).md-warn.md-checked .md-icon {
        background-color: rgba(198, 40, 40, 0.87);
    }

    &.md-default-theme.md-hue-2:not([disabled]).md-warn.md-checked.md-focused:not([disabled]) .md-container:before, &.md-hue-2:not([disabled]).md-warn.md-checked.md-focused:not([disabled]) .md-container:before {
        background-color: rgba(198, 40, 40, 0.26);
    }

    &.md-default-theme.md-hue-2:not([disabled]).md-warn.md-checked .md-icon:after, &.md-hue-2:not([disabled]).md-warn.md-checked .md-icon:after {
        border-color: rgb(238, 238, 238);
    }
}

.md-default-theme.md-hue-2 .md-datepicker-input-container.md-datepicker-invalid, .md-hue-2 .md-datepicker-input-container.md-datepicker-invalid {
    border-bottom-color: rgb(213, 0, 0);
}

.md-warn {
    .md-default-theme.md-hue-2 .md-datepicker-input-container.md-datepicker-focused, .md-hue-2 .md-datepicker-input-container.md-datepicker-focused {
        border-bottom-color: rgb(213, 0, 0);
    }
}

.md-default-theme.md-hue-2 .md-datepicker-open.md-warn .md-datepicker-calendar-icon, .md-hue-2 .md-datepicker-open.md-warn .md-datepicker-calendar-icon {
    color: rgb(213, 0, 0);
}

.md-warn {
    .md-default-theme.md-hue-2 .md-datepicker-open .md-datepicker-calendar-icon, .md-hue-2 .md-datepicker-open .md-datepicker-calendar-icon {
        color: rgb(213, 0, 0);
    }
}

md-icon {
    &.md-default-theme.md-hue-2.md-warn, &.md-hue-2.md-warn {
        color: rgb(198, 40, 40);
    }
}

md-input-container {
    &.md-default-theme.md-hue-2 label.md-required:after, &.md-hue-2 label.md-required:after, &.md-default-theme.md-hue-2 .md-input-message-animation, &.md-hue-2 .md-input-message-animation, &.md-default-theme.md-hue-2 .md-input-messages-animation, &.md-hue-2 .md-input-messages-animation {
        color: rgb(213, 0, 0);
    }

    &.md-default-theme.md-hue-2:not(.md-input-invalid).md-input-focused.md-warn .md-input, &.md-hue-2:not(.md-input-invalid).md-input-focused.md-warn .md-input {
        border-color: rgb(213, 0, 0);
    }

    &.md-default-theme.md-hue-2:not(.md-input-invalid).md-input-focused.md-warn label, &.md-hue-2:not(.md-input-invalid).md-input-focused.md-warn label, &.md-default-theme.md-hue-2:not(.md-input-invalid).md-input-focused.md-warn md-icon, &.md-hue-2:not(.md-input-invalid).md-input-focused.md-warn md-icon {
        color: rgb(213, 0, 0);
    }

    &.md-default-theme.md-hue-2.md-input-invalid .md-input, &.md-hue-2.md-input-invalid .md-input {
        border-color: rgb(213, 0, 0);
    }

    &.md-default-theme.md-hue-2.md-input-invalid .md-char-counter, &.md-hue-2.md-input-invalid .md-char-counter, &.md-default-theme.md-hue-2.md-input-invalid .md-input-message-animation, &.md-hue-2.md-input-invalid .md-input-message-animation, &.md-default-theme.md-hue-2.md-input-invalid label, &.md-hue-2.md-input-invalid label {
        color: rgb(213, 0, 0);
    }
}

md-nav-bar {
    &.md-default-theme.md-hue-2.md-warn > .md-nav-bar, &.md-hue-2.md-warn > .md-nav-bar {
        background-color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2.md-warn > .md-nav-bar .md-button._md-nav-button, &.md-hue-2.md-warn > .md-nav-bar .md-button._md-nav-button {
        color: rgb(255, 205, 210);
    }

    &.md-default-theme.md-hue-2.md-warn > .md-nav-bar .md-button._md-nav-button.md-active, &.md-hue-2.md-warn > .md-nav-bar .md-button._md-nav-button.md-active, &.md-default-theme.md-hue-2.md-warn > .md-nav-bar .md-button._md-nav-button.md-focused, &.md-hue-2.md-warn > .md-nav-bar .md-button._md-nav-button.md-focused {
        color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme.md-hue-2.md-warn > .md-nav-bar .md-button._md-nav-button.md-focused, &.md-hue-2.md-warn > .md-nav-bar .md-button._md-nav-button.md-focused {
        background: rgba(255, 255, 255, 0.1);
    }
}

md-toolbar.md-warn > md-nav-bar {
    &.md-default-theme.md-hue-2 > .md-nav-bar, &.md-hue-2 > .md-nav-bar {
        background-color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2 > .md-nav-bar .md-button._md-nav-button, &.md-hue-2 > .md-nav-bar .md-button._md-nav-button {
        color: rgb(255, 205, 210);
    }

    &.md-default-theme.md-hue-2 > .md-nav-bar .md-button._md-nav-button.md-active, &.md-hue-2 > .md-nav-bar .md-button._md-nav-button.md-active, &.md-default-theme.md-hue-2 > .md-nav-bar .md-button._md-nav-button.md-focused, &.md-hue-2 > .md-nav-bar .md-button._md-nav-button.md-focused {
        color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme.md-hue-2 > .md-nav-bar .md-button._md-nav-button.md-focused, &.md-hue-2 > .md-nav-bar .md-button._md-nav-button.md-focused {
        background: rgba(255, 255, 255, 0.1);
    }
}

md-progress-circular {
    &.md-default-theme.md-hue-2.md-warn path, &.md-hue-2.md-warn path {
        stroke: rgb(198, 40, 40);
    }
}

md-progress-linear {
    &.md-default-theme.md-hue-2.md-warn .md-container, &.md-hue-2.md-warn .md-container {
        background-color: rgb(255, 205, 210);
    }

    &.md-default-theme.md-hue-2.md-warn .md-bar, &.md-hue-2.md-warn .md-bar {
        background-color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2[md-mode=buffer].md-warn .md-bar1, &.md-hue-2[md-mode=buffer].md-warn .md-bar1 {
        background-color: rgb(255, 205, 210);
    }

    &.md-default-theme.md-hue-2[md-mode=buffer].md-warn .md-dashed:before, &.md-hue-2[md-mode=buffer].md-warn .md-dashed:before {
        background: radial-gradient(rgb(255, 205, 210) 0, rgb(255, 205, 210) 16%, transparent 42%);
    }
}

md-radio-button {
    &.md-default-theme.md-hue-2:not([disabled]).md-warn .md-on, &.md-hue-2:not([disabled]).md-warn .md-on, &.md-default-theme.md-hue-2:not([disabled]) .md-warn .md-on, &.md-hue-2:not([disabled]) .md-warn .md-on {
        background-color: rgba(198, 40, 40, 0.87);
    }
}

md-radio-group {
    &.md-default-theme.md-hue-2:not([disabled]).md-warn .md-on, &.md-hue-2:not([disabled]).md-warn .md-on, &.md-default-theme.md-hue-2:not([disabled]) .md-warn .md-on, &.md-hue-2:not([disabled]) .md-warn .md-on {
        background-color: rgba(198, 40, 40, 0.87);
    }
}

md-radio-button {
    &.md-default-theme.md-hue-2:not([disabled]).md-warn.md-checked .md-off, &.md-hue-2:not([disabled]).md-warn.md-checked .md-off, &.md-default-theme.md-hue-2:not([disabled]) .md-warn.md-checked .md-off, &.md-hue-2:not([disabled]) .md-warn.md-checked .md-off, &.md-default-theme.md-hue-2:not([disabled]).md-warn .md-checked .md-off, &.md-hue-2:not([disabled]).md-warn .md-checked .md-off, &.md-default-theme.md-hue-2:not([disabled]) .md-warn .md-checked .md-off, &.md-hue-2:not([disabled]) .md-warn .md-checked .md-off {
        border-color: rgba(198, 40, 40, 0.87);
    }
}

md-radio-group {
    &.md-default-theme.md-hue-2:not([disabled]).md-warn.md-checked .md-off, &.md-hue-2:not([disabled]).md-warn.md-checked .md-off, &.md-default-theme.md-hue-2:not([disabled]) .md-warn.md-checked .md-off, &.md-hue-2:not([disabled]) .md-warn.md-checked .md-off, &.md-default-theme.md-hue-2:not([disabled]).md-warn .md-checked .md-off, &.md-hue-2:not([disabled]).md-warn .md-checked .md-off, &.md-default-theme.md-hue-2:not([disabled]) .md-warn .md-checked .md-off, &.md-hue-2:not([disabled]) .md-warn .md-checked .md-off {
        border-color: rgba(198, 40, 40, 0.87);
    }
}

md-radio-button {
    &.md-default-theme.md-hue-2:not([disabled]).md-warn.md-checked .md-ink-ripple, &.md-hue-2:not([disabled]).md-warn.md-checked .md-ink-ripple, &.md-default-theme.md-hue-2:not([disabled]) .md-warn.md-checked .md-ink-ripple, &.md-hue-2:not([disabled]) .md-warn.md-checked .md-ink-ripple, &.md-default-theme.md-hue-2:not([disabled]).md-warn .md-checked .md-ink-ripple, &.md-hue-2:not([disabled]).md-warn .md-checked .md-ink-ripple, &.md-default-theme.md-hue-2:not([disabled]) .md-warn .md-checked .md-ink-ripple, &.md-hue-2:not([disabled]) .md-warn .md-checked .md-ink-ripple {
        color: rgba(198, 40, 40, 0.87);
    }
}

md-radio-group {
    &.md-default-theme.md-hue-2:not([disabled]).md-warn.md-checked .md-ink-ripple, &.md-hue-2:not([disabled]).md-warn.md-checked .md-ink-ripple, &.md-default-theme.md-hue-2:not([disabled]) .md-warn.md-checked .md-ink-ripple, &.md-hue-2:not([disabled]) .md-warn.md-checked .md-ink-ripple, &.md-default-theme.md-hue-2:not([disabled]).md-warn .md-checked .md-ink-ripple, &.md-hue-2:not([disabled]).md-warn .md-checked .md-ink-ripple, &.md-default-theme.md-hue-2:not([disabled]) .md-warn .md-checked .md-ink-ripple, &.md-hue-2:not([disabled]) .md-warn .md-checked .md-ink-ripple {
        color: rgba(198, 40, 40, 0.87);
    }
}

md-radio-button {
    &.md-default-theme.md-hue-2:not([disabled]).md-warn .md-container .md-ripple, &.md-hue-2:not([disabled]).md-warn .md-container .md-ripple, &.md-default-theme.md-hue-2:not([disabled]) .md-warn .md-container .md-ripple, &.md-hue-2:not([disabled]) .md-warn .md-container .md-ripple {
        color: rgb(229, 57, 53);
    }
}

md-radio-group {
    &.md-default-theme.md-hue-2:not([disabled]).md-warn .md-container .md-ripple, &.md-hue-2:not([disabled]).md-warn .md-container .md-ripple, &.md-default-theme.md-hue-2:not([disabled]) .md-warn .md-container .md-ripple, &.md-hue-2:not([disabled]) .md-warn .md-container .md-ripple {
        color: rgb(229, 57, 53);
    }

    &.md-default-theme.md-hue-2.md-focused:not(:empty) .md-checked.md-warn .md-container:before, &.md-hue-2.md-focused:not(:empty) .md-checked.md-warn .md-container:before, &.md-default-theme.md-hue-2.md-focused:not(:empty).md-warn .md-checked .md-container:before, &.md-hue-2.md-focused:not(:empty).md-warn .md-checked .md-container:before {
        background-color: rgba(198, 40, 40, 0.26);
    }
}

md-input-container {
    md-select {
        &.md-default-theme.md-hue-2 .md-select-value span:first-child:after, &.md-hue-2 .md-select-value span:first-child:after {
            color: rgb(213, 0, 0);
        }
    }

    &.md-input-invalid md-select {
        &.md-default-theme.md-hue-2 .md-select-value, &.md-hue-2 .md-select-value {
            color: rgb(213, 0, 0) !important;
            border-bottom-color: rgb(213, 0, 0) !important;
        }
    }
}

md-select {
    &.md-default-theme.md-hue-2 .md-select-value span:first-child:after, &.md-hue-2 .md-select-value span:first-child:after {
        color: rgb(213, 0, 0);
    }

    &.md-default-theme.md-hue-2.ng-invalid.ng-touched .md-select-value, &.md-hue-2.ng-invalid.ng-touched .md-select-value {
        color: rgb(213, 0, 0) !important;
        border-bottom-color: rgb(213, 0, 0) !important;
    }

    &.md-default-theme.md-hue-2:not([disabled]):focus.md-warn .md-select-value, &.md-hue-2:not([disabled]):focus.md-warn .md-select-value {
        border-bottom-color: rgb(198, 40, 40);
    }
}

md-slider {
    &.md-default-theme.md-hue-2.md-warn .md-focus-ring, &.md-hue-2.md-warn .md-focus-ring {
        background-color: rgba(239, 154, 154, 0.38);
    }

    &.md-default-theme.md-hue-2.md-warn .md-track.md-track-fill, &.md-hue-2.md-warn .md-track.md-track-fill {
        background-color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2.md-warn .md-thumb:after, &.md-hue-2.md-warn .md-thumb:after {
        border-color: rgb(198, 40, 40);
        background-color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2.md-warn .md-sign, &.md-hue-2.md-warn .md-sign {
        background-color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2.md-warn .md-sign:after, &.md-hue-2.md-warn .md-sign:after {
        border-top-color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2.md-warn[md-vertical] .md-sign:after, &.md-hue-2.md-warn[md-vertical] .md-sign:after {
        border-top-color: transparent;
        border-left-color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2.md-warn .md-thumb-text, &.md-hue-2.md-warn .md-thumb-text {
        color: rgba(255, 255, 255, 0.87);
    }
}

.md-subheader {
    &.md-default-theme.md-hue-2.md-warn, &.md-hue-2.md-warn {
        color: rgb(198, 40, 40);
    }
}

md-switch {
    &.md-default-theme.md-hue-2.md-checked.md-warn .md-ink-ripple, &.md-hue-2.md-checked.md-warn .md-ink-ripple {
        color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2.md-checked.md-warn .md-thumb, &.md-hue-2.md-checked.md-warn .md-thumb {
        background-color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2.md-checked.md-warn .md-bar, &.md-hue-2.md-checked.md-warn .md-bar {
        background-color: rgba(198, 40, 40, 0.5);
    }

    &.md-default-theme.md-hue-2.md-checked.md-warn.md-focused .md-thumb:before, &.md-hue-2.md-checked.md-warn.md-focused .md-thumb:before {
        background-color: rgba(198, 40, 40, 0.26);
    }
}

md-tabs {
    &.md-default-theme.md-hue-2.md-warn > md-tabs-wrapper, &.md-hue-2.md-warn > md-tabs-wrapper {
        background-color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), &.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), &.md-default-theme.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon, &.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
        color: rgb(255, 205, 210);
    }

    &.md-default-theme.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, &.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, &.md-default-theme.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, &.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, &.md-default-theme.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-default-theme.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon, &.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
        color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-hue-2.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
        background: rgba(255, 255, 255, 0.1);
    }
}

md-toolbar.md-warn > md-tabs {
    &.md-default-theme.md-hue-2 > md-tabs-wrapper, &.md-hue-2 > md-tabs-wrapper {
        background-color: rgb(198, 40, 40);
    }

    &.md-default-theme.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), &.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), &.md-default-theme.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon, &.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
        color: rgb(255, 205, 210);
    }

    &.md-default-theme.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, &.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, &.md-default-theme.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, &.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, &.md-default-theme.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-default-theme.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon, &.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
        color: rgba(255, 255, 255, 0.87);
    }

    &.md-default-theme.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, &.md-hue-2 > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
        background: rgba(255, 255, 255, 0.1);
    }
}

md-toast {
    &.md-default-theme.md-hue-2 .md-toast-content .md-button.md-highlight.md-warn, &.md-hue-2 .md-toast-content .md-button.md-highlight.md-warn {
        color: rgb(198, 40, 40);
    }
}

md-toolbar {
    &.md-default-theme.md-hue-2:not(.md-menu-toolbar).md-warn, &.md-hue-2:not(.md-menu-toolbar).md-warn {
        background-color: rgb(198, 40, 40);
        color: rgba(255, 255, 255, 0.87);
    }
}