// Angular Material Theme
@import "~angular-material/angular-material.scss";

// Core
@import "./spa/index";

// UI Components
@import "./spa/ui/index";

// Component Styles
@import "./spa/app/login";

.is-static {
    a {
        color: $accentHue2Color;

        &:visited,
        &:active {
            color: $accentHue2Color;
        }
    }

    md-input-container {
        input:read-only,
        .md-input:read-only {
            background: #f5f5f5;
            color: #656464 !important;
        }
    }

    #content {
        background-color: $color-blue;
        background-size: cover;

        @media screen and (min-width: $layout-breakpoint-sm) {
            background-image: url('../images/login-background.jpg');
        }
    }

    .footer {
        color: #fff;
    }

    .static--container {
        height: 100%;
        background-size: cover;
        padding: 0 1em;

        @media screen and (min-width: $layout-breakpoint-sm) {
            padding: 0;
        }
    }

    .static--wrapper {
        max-width: 800px;
        margin: 0 auto;
        background: #fff;

        @media screen and (min-width: $layout-breakpoint-sm) {
            margin: 5% auto 0;
        }
    }

    .static--content {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        padding: 12px;
    }

    .static-logo {
        width: 100%;
        max-width: 340px;
    }
}